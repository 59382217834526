<template>
	<div
		:class="{
			'reward-options': true,
			'is-enabled': enabled,
		}"
		:aria-hidden="! enabled"
	>
		<div
			v-for="(label, index) in boxes"
			:key="index"
			:class="[ 'reward-option', `reward-option--${ index + 1 }` ]"
		>
			<button
				ref="dropzones"
				:class="{
					'reward-option__dropzone': true,
					'is-targeted': enabled && targetDropzone === index,
				}"
				:disabled="! enabled"
				@click="$emit( 'select', index )"
			>
				<div class="reward-option__dropzone__ring" />
				{{ label }}
			</button>

			<AppImage
				:srcset="[
					require(`@/assets/img/reward-${ index + 1 }.webp`),
					require(`@/assets/img/reward-${ index + 1 }.png`),
				]"
				class="reward-option__box"
				width="195"
				height="156"
			/>
		</div>
		<div
			ref="draggable"
			:class="{
				'reward-options__draggable': true,
				'is-droppable': enabled && targetDropzone !== -1,
			}"
		>
			<AppCharacter
				:type="avatar.name"
				:color="avatar.color"
				animation="interaction"
				:clip="animation"
			/>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import { gsap, Draggable } from '@/utilities/gsap';
import checkIntersect from '@/utilities/check-intersect';

export default {
	props: {
		enabled: Boolean,
		boxes: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			isDragging: false,
			targetDropzone: -1,
		};
	},
	computed: {
		...mapState( [ 'avatar' ] ),

		animation() {
			return this.isDragging ? 'picked' : 'pickme';
		},
	},
	watch: {
		enabled( isEnabled ) {
			if ( this.draggable ) {
				if ( isEnabled ) {
					this.draggable.enable();
				} else {
					this.draggable.disable();
				}
			}
		},
	},
	mounted() {
		const draggable = this.$refs.draggable;

		const [ drag ] = Draggable.create( draggable, {
			zIndexBoost: false,
			onPress: () => {
				this.isDragging = true;
			},
			onRelease: () => {
				this.isDragging = false;
			},
			onDragStart: () => {
				this.$sounds.play( 'pickup' );
			},
			onDrag: () => {
				this.checkIntersect();
			},
			onDragEnd: () => {
				this.checkIntersect();
				if ( this.targetDropzone >= 0 ) {
					this.$emit( 'select', this.targetDropzone );
				} else {
					this.reset();
				}
			},
		} );

		this.draggable = drag;
	},
	methods: {
		checkIntersect() {
			this.targetDropzone = this.$refs.dropzones.findIndex( ( dropzone ) => {
				return checkIntersect( this.$refs.draggable, dropzone, -0.5 );
			} );
		},
		reset() {
			if ( ! this.draggable ) {
				return;
			}

			this.isAnimating = true;
			this.draggable.disable();
			gsap.to( this.$refs.draggable, {
				x: 0,
				y: 0,
				ease: 'power2.out',
				duration: .4,
				onComplete: () => {
					this.isAnimating = false;

					this.draggable.update();
					if ( this.enabled ) {
						this.draggable.enable();
					}
				},
			} );
		},
	},
};
</script>

<style lang="scss">
@keyframes rewardDropzone {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.reward-options {
	position: relative;
	display: flex;
	justify-content: space-between;
	max-width: 390px;
	margin: auto;

	&__draggable {
		position: absolute;
		z-index: 2;
		top: 200px;
		left: 50%;
		width: 200px;
		margin-left: -100px;

		.character {
			transform: scale(0);
			transition: transform .2s ease-out;

			.is-enabled & {
				transform: scale(1);

				.build-enter-active & {
					animation: slideInBottom .4s ease-out both;
					animation-delay: 1.2s;
				}
				.build-leave-active & {
					animation: slideOutBottom .4s ease-out both;
				}
			}
		}

		&.is-droppable {
			.character {
				transform: scale(.8);
			}
		}
	}
}
	.reward-option {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 50%;

		.is-enabled & {
			&--1 {
				.build-enter-active & {
					animation: slideInLeft .4s ease-out both;
					animation-delay: .4s;
				}
			}
			&--2 {
				.build-enter-active & {
					animation: slideInRight .4s ease-out both;
					animation-delay: .6s;
				}
			}
		}

		&__dropzone {
			position: relative;
			z-index: 1;
			@include size(160px);
			font-weight: $font-weight-heavy;
			transition: transform .2s ease-out;
			transform: scale(0);

			.reward-option--1 & {
				margin-right: auto;
			}
			.reward-option--2 & {
				margin-left: auto;
			}

			&__ring {
				position: absolute;
				inset: 0;
				background: rgba(#000, .16);
				border: 4px dashed $color-white;
				border-radius: 50%;
			}

			.is-enabled & {
				transform: scale(1);

				.build-enter-active & {
					animation: zoomIn .2s ease-out both;
					animation-delay: 1.4s;
				}
				.build-leave-active & {
					animation: zoomOut .4s ease-out both;
				}
			}

			&.is-targeted {
				transform: scale(1.1);

				.reward-option__dropzone__ring {
					animation: rewardDropzone 4s linear infinite;
				}
			}
		}

		&__box {
			margin-top: -40px;
		}
	}
</style>
