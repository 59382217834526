<template>
	<li
		class="carousel__slide"
		@click="$emit( 'select' )"
	>
		<slot />
	</li>
</template>

<script>
// probably-bad-idea
const node2vnode = new WeakMap();

// Just one observer for all instances
const snapObserver = new IntersectionObserver( ( entries ) => {
	entries.forEach( ( entry ) => {
		const event = entry.isIntersecting ? 'enter' : 'leave';

		const vnode = node2vnode.get( entry.target );
		if ( vnode ) {
			vnode.$emit( event );
		}
	} );
}, {
	rootMargin: '0px 50% 0px',
	threshold: 0.5,
} );

export default {
	emits: [ 'enter', 'leave', 'select' ],
	mounted() {
		node2vnode.set( this.$el, this );
		snapObserver.observe( this.$el );
	},
	destroyed() {
		node2vnode.delete( this.$el );
		snapObserver.unobserve( this.$el );
	},
};
</script>

<style lang="scss">
.carousel__slide {
	scroll-snap-align: center;
}
</style>
