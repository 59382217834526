<template>
	<svg
		:class="{
			'timer-shape': true,
			'is-animating': animate,
		}"
		:viewBox="`0 0 ${ width } ${ height }`"
		:width="width"
		:height="height"
	>
		<mask
			:id="uid"
			class="timer-shape__mask"
		>
			<circle
				:r="width - 10"
				:cx="width / 2"
				:cy="height / 2"
			/>
		</mask>
		<g class="timer-shape__outline">
			<circle
				:r="width - 2"
				:cx="width / 2"
				:cy="height / 2"
			/>
		</g>
		<g :mask="`url(#${ uid })`">
			<TimerShapeFill
				v-if="duration"
				class="timer-shape__timer"
				:width="width"
				:height="height"
				:duration="duration * 1000"
				:start="start"
				:paused="paused"
				:delay="delay * 1000"
				@done="$emit( 'expire' )"
			/>
			<rect
				v-else
				x="0"
				y="0"
				:width="width"
				:height="height"
				:fill="altFill"
				:fill-opacity="altOpacity"
			/>
		</g>
	</svg>
</template>

<script>
import TimerShapeFill from './TimerShapeFill';

export default {
	components: {
		TimerShapeFill,
	},
	props: {
		width: {
			type: Number,
			default: 100,
		},
		height: {
			type: Number,
			default: 100,
		},
		duration: {
			type: Number,
			default: 0,
		},
		delay: {
			type: Number,
			default: 0,
		},
		start: Boolean,
		paused: Boolean,
		animate: Boolean,
	},
	data() {
		return {
			uid: 'v' + Date.now(),
			timerFill: 'black',
			timerOpacity: 0.05,
			altFill: 'none',
			altOpacity: 0,
		};
	},
};
</script>

<style lang="scss">
.timer-shape {
	background: rgba(#fff, .3);
	border-radius: 50%;

	&__mask {
		fill: white;
	}

	&__outline {
		fill: none;
	}
}
</style>
