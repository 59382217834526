var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,_vm._b({tag:"Component",class:( _obj = {
		'button': ! _vm.isPlain,

		'is-primary': _vm.isPrimary,
		'is-secondary': _vm.isSecondary,
		'is-link': _vm.isLink,

		'is-large': _vm.isLarge,
		'is-small': _vm.isSmall
	}, _obj[ ("is-color-" + _vm.color) ] = _vm.color, _obj['with-icon'] =  !! _vm.icon, _obj['with-text'] =  _vm.withText, _obj['with-graphic'] =  _vm.graphic, _obj ),attrs:{"type":_vm.tag === 'button' ? _vm.type : null,"tabindex":_vm.isParentActive ? null : -1},on:{"click":_vm.onClick}},'Component',_vm.attrs,false),[(_vm.icon)?_c('span',{staticClass:"button__inner"},[(_vm.withText)?_c('span',{staticClass:"button__text"},[_vm._t("default")],2):_c('ScreenReaderText',[_vm._t("default")],2),_c('AppGraphic',{attrs:{"type":_vm.icon}})],1):_c('span',{staticClass:"button__inner"},[(_vm.graphic)?_c('AppImage',{staticClass:"button__graphic",attrs:{"srcset":_vm.graphic,"width":"90","height":"90"}}):_vm._e(),_c('span',{staticClass:"button__text"},[_vm._t("default")],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }