<template>
	<div>
		<div v-html="about" />
		<p v-if="privacyPolicy || termsOfUse">
			<a
				v-if="privacyPolicy"
				href="#privacypolicy"
				@click.prevent="showModal( 'privacypolicy' )"
			>{{ $l10n( 'privacy-policy' ) }}</a>
			{{ privacyPolicy && termsOfUse ? ' &nbsp;|&nbsp; ' : '' }}
			<a
				v-if="termsOfUse"
				href="#termsofuse"
				@click.prevent="showModal( 'termsofuse' )"
			>{{ $l10n( 'terms-of-use' ) }}</a>
		</p>
	</div>
</template>

<script>
export default {
	props: {
		about: {
			type: String,
			required: true,
		},
		privacyPolicy: {
			type: String,
			default: null,
		},
		termsOfUse: {
			type: String,
			default: null,
		},
	},
};
</script>
