<template>
	<div class="option-question">
		<div class="option-question__header">
			<h2 v-if="title" v-html="title" />
			<div v-html="content" />
		</div>

		<div class="option-question__body">
			<OptionButtons
				:options="options"
				is-large
				is-stacked
				with-graphics
				@change="$emit( 'answer', $event )"
			/>
		</div>

		<div v-if="isSkippable" class="option-question__footer">
			<AppButton
				is-circular
				@click="$emit( 'answer', 'abstain' )"
			>
				{{ $l10n( 'skip' ) }}
			</AppButton>
		</div>
	</div>
</template>

<script>
import OptionButtons from './OptionButtons.vue';

export default {
	components: {
		OptionButtons,
	},
	inheritAttrs: false,
	props: {
		title: {
			type: String,
			required: true,
		},
		content: {
			type: String,
			required: true,
		},
		values: {
			type: Array,
			required: true,
		},
		labels: {
			type: Array,
			required: true,
		},
		emotes: {
			type: Array,
			default: () => [],
		},
		isSkippable: Boolean,
	},
	data() {
		const options = [];
		const optionCount = this.values.length;

		for ( let i = 0; i < optionCount; i++ ) {
			options.push( {
				value: this.values[ i ],
				label: this.labels[ i ] || this.values[ i ],
				emote: this.emotes[ i ],
			} );
		}

		return {
			options,
		};
	},
};
</script>

<style lang="scss">
.option-question {
	position: relative;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	text-align: center;

	&__header {
		margin-top: auto;

		.build-enter-active & {
			animation: slideInLeft .4s ease-out both;
		}
		.build-leave-active & {
			animation: slideOutRight .4s ease-out both;
		}
	}

	&__body {
		margin-bottom: auto;
	}
	&__footer {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-top: auto;

		> :not(:first-child) {
			margin-left: 1em;
		}

		.build-enter-active & {
			animation: slideInBottom .4s ease-out both;
			animation-delay: .8s;
		}
		.build-leave-active & {
			animation: slideOutBottom .4s ease-out both;
		}
	}

	@include breakpoint($min-height: 800) {
		&__header {
			margin-top: auto;
		}
	}
}
</style>
