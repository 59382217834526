<template>
	<div
		:class="{
			'levelbar': true,
			'is-maxed': max && level === max,
			'static': isStatic,
			'animate': animate,
			[ `animate--${ animate }` ]: animate,
		}"
	>
		<div class="levelbar__meter">
			<div class="levelbar__meter__fill" />

			<StarGraphic class="levelbar__star levelbar__value">
				<span
					v-if="level !== null"
					class="star__label"
				>
					{{ level }}
				</span>
				<template v-else>
					<span class="star__label start-label">
						{{ oldLevel }}
					</span>
					<span class="star__label end-label">
						{{ newLevel }}
					</span>
				</template>
			</StarGraphic>
			<StarGraphic class="levelbar__star levelbar__placeholder">
				<span
					v-if="level !== null"
					class="star__label"
				>
					{{ level + 1 }}
				</span>
				<span
					v-else
					class="star__label"
				>
					{{ newLevel }}
				</span>
			</StarGraphic>
		</div>
	</div>
</template>

<script>
import StarGraphic from './StarGraphic';

export default {
	components: {
		StarGraphic,
	},
	props: {
		level: {
			type: Number,
			default: null,
		},
		max: {
			type: Number,
			default: null,
		},
		oldLevel: {
			type: Number,
			default: null,
		},
		newLevel: {
			type: Number,
			default: null,
		},
		animate: {
			type: String,
			default: null,
		},
		isStatic: Boolean,
	},
};
</script>

<style lang="scss">
@keyframes levelbarFill {
	from {
		width: 30%;
	}
	to {
		width: 100%;
	}
}

@keyframes levelbarStar {
	from {
		left: 30%;
		filter: drop-shadow(0 10px 0 rgba(#000, .16));
	}
	to {
		left: 100%;
		filter: drop-shadow(0 0 0 rgba(#000, 0));
	}
}

@keyframes levelbarPlaceholder {
	0%, 80% {
		fill: $color-green;
		stroke-width: 6;
	}
	100% {
		fill: $color-white;
		stroke-width: 0;
	}
}

@keyframes levelbarStarIcon {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(75deg);
	}
}

@keyframes levelbarLabelStart {
	0%, 50% {
		opacity: 1;
	}
	80%, 100% {
		opacity: 0;
	}
}

@keyframes levelbarLabelEnd {
	0%, 80% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@mixin levebar-animation($name, $duration: .3s, $delay: 1.2s, $easing: ease-out) {
	animation: $duration var(--easeing, #{$easing}) $delay var(--direction, normal) both var(--play-state, paused) $name;
}

.levelbar {
	position: relative;
	margin: 40px auto;
	width: 100%;

	&__meter {
		position: relative;
		margin-right: 50px;
		height: 30px;
		background: rgba($color-white, .5);
		border-radius: 30px;

		&__fill {
			height: 100%;
			border-radius: inherit;
			background: #51E8FD;
			@include levebar-animation(levebarFill);

			.static & {
				width: 30%;
				animation: none;
			}

			.is-maxed & {
				width: 100%;
				animation: none;
			}
		}
	}

	&__star {
		position: absolute;
		top: 50%;
		width: 96px;
		height: 92px;
		margin-top: -55px;
		margin-left: -48px;

		.star__label {
			color: $color-white;
		}
	}

	&__value {
		z-index: 1;
		@include levebar-animation(levelbarStar);

		.star__icon {
			@include levebar-animation(levelbarStarIcon);
		}

		.start-label {
			@include levebar-animation(levelbarLabelStart);
		}
		.end-label {
			@include levebar-animation(levelbarLabelEnd);
		}

		.static &,
		.is-maxed & {
			animation: none;
		}

		.static & {
			left: 30%;

			.star__icon,
			.start-label,
			.end-label {
				animation: none;
			}
		}

		.is-maxed & {
			left: 100%;

			.star__icon,
			.start-label,
			.end-label {
				animation: none;
			}
		}
	}
	&__placeholder {
		left: 100%;

		.star__icon {
			fill: $color-light;
			stroke: $color-pink;
			stroke-width: 6;
			transform: rotate(75deg);
			@include levebar-animation(levelbarPlaceholder);

			.static &,
			.is-maxed & {
				animation: none;
			}
		}
		.star__label {
			color: $color-black;
			opacity: .5;
		}

		.is-maxed & {
			display: none;
		}
	}

	&.animate {
		--play-state: running;
	}

	&.animate--down {
		--easing: ease-in;
		--direction: reverse;
	}
}
</style>
