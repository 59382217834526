var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
		'reward-options': true,
		'is-enabled': _vm.enabled,
	},attrs:{"aria-hidden":! _vm.enabled}},[_vm._l((_vm.boxes),function(label,index){return _c('div',{key:index,class:[ 'reward-option', ("reward-option--" + (index + 1)) ]},[_c('button',{ref:"dropzones",refInFor:true,class:{
				'reward-option__dropzone': true,
				'is-targeted': _vm.enabled && _vm.targetDropzone === index,
			},attrs:{"disabled":! _vm.enabled},on:{"click":function($event){return _vm.$emit( 'select', index )}}},[_c('div',{staticClass:"reward-option__dropzone__ring"}),_vm._v(" "+_vm._s(label)+" ")]),_c('AppImage',{staticClass:"reward-option__box",attrs:{"srcset":[
				require(("@/assets/img/reward-" + (index + 1) + ".webp")),
				require(("@/assets/img/reward-" + (index + 1) + ".png")) ],"width":"195","height":"156"}})],1)}),_c('div',{ref:"draggable",class:{
			'reward-options__draggable': true,
			'is-droppable': _vm.enabled && _vm.targetDropzone !== -1,
		}},[_c('AppCharacter',{attrs:{"type":_vm.avatar.name,"color":_vm.avatar.color,"animation":"interaction","clip":_vm.animation}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }