var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppScreen',{staticClass:"gamescreen",attrs:{"name":_vm.id,"transition":{
		name: 'build',
		css: false,
		duration: 2000,
	},"aria-live":"polite"},on:{"toggle":_vm.toggleActive}},[_c('Transition',{attrs:{"name":"build","type":"animation","duration":1000,"mode":"out-in"}},[(! _vm.hasResult)?_c('div',{key:"question",staticClass:"gamescreen__inner gamescreen__question"},[_c(_vm.uiComponent,_vm._b({key:"question",tag:"Component",attrs:{"title":_vm.title,"content":_vm.text,"is-active":_vm.isActive,"is-repeat":_vm.isRepeat,"is-skippable":_vm.skippable},on:{"answer":_vm.logAnswer}},'Component',_vm.control,false))],1):_c('div',{key:"result",staticClass:"gamescreen__inner gamescreen__result"},[_c('div',{staticClass:"gamescreen__header"},[_c('h2',{directives:[{name:"content",rawName:"v-content",value:(_vm.resultTitle),expression:"resultTitle"}],staticClass:"gamescreen__title"}),_c('div',{directives:[{name:"content",rawName:"v-content",value:(_vm.correct_text),expression:"correct_text"}],staticClass:"gamescreen__details"}),_c('AppButton',{attrs:{"name":"read-more","question":_vm.name,"is-tertiary":""},on:{"click":function($event){return _vm.showModal( _vm.moreModalId )}}},[_vm._v(" "+_vm._s(_vm.$l10n( 'read-more' ))+" ")])],1),_c('div',{class:[
					'gamescreen__graphic',
					("gamescreen__graphic--" + _vm.result)
				],attrs:{"aria-hidden":"true"}},[(_vm.result === 'timeout')?_c('AppCharacter',{attrs:{"id":"lights","animation":"andon"}}):_c('AppCharacter',{attrs:{"type":"answer","color":_vm.avatar.name,"animation":"reaction","clip":_vm.animation}})],1),_c('div',{staticClass:"gamescreen__footer"},[(_vm.result === 'timeout' && _vm.offerTimers)?[(_vm.useTimers)?_c('AppButton',{staticClass:"disable-timer",attrs:{"name":"disable-timers","question":_vm.name,"icon":"stopwatch","with-text":""},on:{"click":function($event){return _vm.toggleTimers( false )}}},[_vm._v(" "+_vm._s(_vm.$l10n( 'disable-timer' ))+" ")]):_c('AppButton',{staticClass:"enable-timer",attrs:{"is-secondary":"","name":"enable-timers","question":_vm.name,"icon":"stopwatch","with-text":""},on:{"click":function($event){return _vm.toggleTimers( true )}}},[_vm._v(" "+_vm._s(_vm.$l10n( 'enable-timer' ))+" ")])]:_vm._e(),_c('AppButton',{attrs:{"name":"next","question":_vm.name,"is-primary":""},on:{"click":function($event){return _vm.$emit( 'done' )}}},[_vm._v(" "+_vm._s(_vm.$l10n( 'next' ))+" ")])],2)])]),(_vm.hasResult)?_c('Portal',{attrs:{"to":_vm.id}},[_c('AnswerDetails',{attrs:{"id":_vm.moreModalId,"context":_vm.id,"preface":_vm.correct_text,"content":_vm.read_more_text,"src":_vm.source,"source-url":_vm.source_url,"offer-correction":_vm.offerCorrection}}),(_vm.offerCorrection)?_c('CorrectionForm',{attrs:{"id":_vm.correctionModalId,"explanation":_vm.correct_text},on:{"submit":_vm.submitCorrection}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }