<script>
const PATHS = {
	menu: {
		width: 40,
		height: 40,
		path: 'M11.3,22.7c1.5,0,2.7-1.2,2.7-2.7c0-1.5-1.2-2.7-2.7-2.7c-1.5,0-2.7,1.2-2.7,2.7C8.6,21.5,9.8,22.7,11.3,22.7zM20,22.7c1.5,0,2.7-1.2,2.7-2.7c0-1.5-1.2-2.7-2.7-2.7c-1.5,0-2.7,1.2-2.7,2.7C17.3,21.5,18.5,22.7,20,22.7z M28.7,22.7c1.5,0,2.7-1.2,2.7-2.7c0-1.5-1.2-2.7-2.7-2.7S26,18.5,26,20C26,21.5,27.2,22.7,28.7,22.7z M20,3c9.4,0,17,7.6,17,17s-7.6,17-17,17S3,29.4,3,20S10.6,3,20,3 M20,0C9,0,0,9,0,20s9,20,20,20s20-9,20-20S31,0,20,0L20,0z',
	},
	sound: {
		width: 59,
		height: 40,
		paths: {
			x: 'M41.3,19.5l3.1-3.3c0.8-0.8,0.9-2,0.3-2.7c-0.6-0.7-1.7-0.5-2.5,0.3l-3.1,3.3L36,13.8c-0.8-0.8-1.9-1-2.5-0.3c-0.6,0.7-0.5,1.9,0.3,2.7l3.1,3.3l-3.1,3.3c-0.8,0.8-0.9,2-0.3,2.7c0.6,0.7,1.7,0.5,2.5-0.3l3.1-3.3l3.1,3.3c0.8,0.8,1.9,1,2.5,0.3c0.6-0.7,0.5-1.9-0.3-2.7L41.3,19.5z',
			shape: 'M28.9,9.3v20.5c0,1.1-0.8,1.6-1.7,1.1l-8.5-4.5H13c-1.1,0-2-0.9-2-2v-9.7c0-1.1,0.9-2,2-2h5.6l8.5-4.5C28.1,7.7,28.9,8.2,28.9,9.3z',
			frame: 'M39,3c9.4,0,17,7.6,17,17c0,9.4-7.6,17-17,17H20c-9.4,0-17-7.6-17-17c0-9.4,7.6-17,17-17H39 M39,0H20C9,0,0,9,0,20v0c0,11,9,20,20,20h19c11,0,20-9,20-20v0C59,9,50,0,39,0L39,0z',
			bar1: 'M33.5,28.8L33.5,28.8c-0.9,0-1.6-0.7-1.6-1.6V11.7c0-0.9,0.7-1.6,1.6-1.6h0c0.9,0,1.6,0.7,1.6,1.6v15.5C35.1,28.1,34.4,28.8,33.5,28.8z',
			bar2: 'M40.6,27.3V11.7c0-0.9-0.7-1.6-1.6-1.6h0c-0.9,0-1.6,0.7-1.6,1.6v15.5c0,0.9,0.7,1.6,1.6,1.6h0C39.9,28.8,40.6,28.1,40.6,27.3z',
			bar3: 'M44.6,28.8L44.6,28.8c-0.9,0-1.6-0.7-1.6-1.6V11.7c0-0.9,0.7-1.6,1.6-1.6h0c0.9,0,1.6,0.7,1.6,1.6v15.5C46.2,28.1,45.4,28.8,44.6,28.8z',
		},
	},
	download: {
		width: 230,
		height: 230,
		path: 'M230,167.4v51c0,1-0.1,2-0.4,2.8c-1,5-5.4,8.8-10.6,8.8H10.9c-5.6,0-10.2-4.3-10.8-9.7C0,219.6,0,219,0,218.3v-51c0-6,4.9-10.9,10.9-10.9c3,0,5.7,1.2,7.7,3.2c2,2,3.2,4.7,3.2,7.7v40.8h186.6v-40.8c0-6,4.9-10.9,10.9-10.9c3,0,5.7,1.2,7.7,3.2C228.8,161.7,230,164.4,230,167.4z M177.6,116.3l-53.4,53.5c-0.1,0.1-0.2,0.2-0.3,0.3c-2,2-4.5,3.2-7,3.6c-0.2,0-0.4,0.1-0.7,0.1c-0.4,0.1-0.9,0.1-1.3,0.1l-1.2-0.1c-0.2,0-0.4-0.1-0.7-0.1c-2.6-0.4-5.1-1.6-7-3.6c-0.1-0.1-0.2-0.2-0.3-0.3l-53.4-53.5c-2.5-2.5-3.7-5.7-3.7-9s1.2-6.5,3.7-9c4.9-4.9,13-4.9,18,0l32.1,32.1V12.7c0-7,5.7-12.7,12.7-12.7c3.5,0,6.7,1.5,9,3.7c2.3,2.3,3.7,5.4,3.7,9v117.8l32.1-32.1c4.9-4.9,13-4.9,18,0C182.5,103.3,182.5,111.4,177.6,116.3z',
	},
	share: {
		width: 230,
		height: 230,
		path: 'M170.1,76.7h11c12.1,0,21.9,9.8,21.9,21.9v109.5c0,12.1-9.8,21.9-21.9,21.9H48.9c-12.1,0-21.9-9.8-21.9-21.9V98.6c0-12.1,9.8-21.9,21.9-21.9h11c6,0,11,4.9,11,11c0,6-4.9,11-11,11h-11v109.5h132.2V98.6h-11c-6,0-11-4.9-11-11C159.2,81.6,164.1,76.7,170.1,76.7z M106.7,3.8c2-2.3,5-3.8,8.3-3.8c3.3,0,6.3,1.5,8.3,3.8l30.4,30.4c4.3,4.3,4.3,11.2,0,15.5c-4.3,4.3-11.2,4.3-15.5,0L126,37.4v104.9c0,6-4.9,11-11,11c-6,0-11-4.9-11-11V37.4L91.8,49.7c-4.3,4.3-11.2,4.3-15.5,0c-4.3-4.3-4.3-11.2,0-15.5L106.7,3.8z',
	},
	stopwatch: {
		width: 18,
		height: 18,
		paths: {
			shape: 'M7.4,3.5C4,4.2,1.5,7.1,1.5,10.7C1.5,14.7,4.9,18,9,18c4.1,0,7.5-3.3,7.5-7.3c0-0.8-0.1-1.6-0.4-2.4L17,7.8c0.2-0.1,0.4-0.3,0.4-0.5c0.1-0.2,0-0.4-0.1-0.6c-0.2-0.4-0.5-0.8-0.7-1.2c-0.1-0.2-0.3-0.3-0.5-0.4C16,5,15.8,5,15.6,5.1l-1,0.5c-1-1.1-2.4-1.9-3.9-2.2v-1h1.4c0.4,0,0.7-0.3,0.7-0.7c0-0.3,0-0.7,0-1.1c0-0.4-0.3-0.7-0.7-0.7c-1.4,0-4.5,0-5.9,0C5.7,0,5.3,0.3,5.3,0.7c0,0.3,0,0.7,0,1.1c0,0.4,0.3,0.7,0.7,0.7h1.4V3.5z M9,4.9c3.2,0,5.8,2.6,5.8,5.7c0,3.1-2.6,5.7-5.8,5.7c-3.2,0-5.8-2.6-5.8-5.7C3.2,7.5,5.8,4.9,9,4.9z',
			disabled: 'M9,9.5L7.1,7.6c-0.3-0.3-0.9-0.3-1.2,0c-0.3,0.3-0.3,0.8,0,1.2l1.9,1.9l-1.9,1.9c-0.3,0.3-0.3,0.8,0,1.2c0.3,0.3,0.9,0.3,1.2,0L9,11.8l1.9,1.9c0.3,0.3,0.9,0.3,1.2,0c0.3-0.3,0.3-0.8,0-1.2l-1.9-1.9l1.9-1.9c0.3-0.3,0.3-0.8,0-1.2c-0.3-0.3-0.9-0.3-1.2,0L9,9.5z',
			enabled: 'M12.7,7.7c0.4,0.4,0.4,1,0,1.4l-5.3,5.1l-2.7-2.7c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l1.3,1.3l3.8-3.7C11.6,7.3,12.3,7.3,12.7,7.7z',
		},
	},
	trophy: {
		width: 230,
		height: 230,
		path: 'M56.9,0c-1.6,0.1-3.1,0.9-4.1,2.1c-1.1,1.2-1.7,2.7-1.7,4.3v6.4H18.9c-0.2,0-0.4,0-0.6,0c-1.6,0.1-3.1,0.9-4.1,2.1c-1.1,1.2-1.7,2.7-1.7,4.3v17.6c0,22.8,16.8,41.9,38.6,45.7v0.6c0,33.1,25.2,60.4,57.5,63.6v45H63.9c-10.5,0-19.2,8.7-19.2,19.2v12.8c0,1.7,0.7,3.3,1.9,4.5s2.8,1.9,4.5,1.9h127.8c1.7,0,3.3-0.7,4.5-1.9s1.9-2.8,1.9-4.5v-12.8c0-10.5-8.7-19.2-19.2-19.2h-44.7v-45c32.3-3.2,57.5-30.5,57.5-63.6v-0.6c21.8-3.8,38.6-22.9,38.6-45.7V19.2c0-1.7-0.7-3.3-1.9-4.5s-2.8-1.9-4.5-1.9h-32.2V6.4c0-1.7-0.7-3.3-1.9-4.5S174.2,0,172.5,0h-115C57.3,0,57.1,0,56.9,0z M63.9,12.8h102.2v70.3c0,28.4-22.7,51.1-51.1,51.1s-51.1-22.7-51.1-51.1C63.9,83.1,63.9,12.8,63.9,12.8z M25.3,25.6h25.8v43.7c-14.7-3.5-25.8-16.6-25.8-32.5V25.6z M178.9,25.6h25.8v11.2c0,15.9-11.1,29.1-25.8,32.6V25.6z M63.9,204.4h102.2c3.7,0,6.4,2.7,6.4,6.4v6.4h-115v-6.4C57.5,207.2,60.2,204.4,63.9,204.4z',
	},
	enlarge: {
		width: 56,
		height: 56,
		path: 'M44.3,39.6l10.7,10.7c0.3,0.3,0.6,0.7,0.8,1.1c0.2,0.4,0.3,0.8,0.3,1.3c0,0.4-0.1,0.9-0.2,1.3c-0.2,0.4-0.4,0.8-0.7,1.1c-0.3,0.3-0.7,0.6-1.1,0.7c-0.4,0.2-0.8,0.2-1.3,0.2c-0.4,0-0.9-0.1-1.3-0.3c-0.4-0.2-0.8-0.4-1.1-0.8L39.6,44.3c-3.1,2.4-6.7,4-10.6,4.7c-3.9,0.7-7.8,0.5-11.6-0.7s-7.2-3.2-10-5.9c-2.8-2.7-4.9-6.1-6.2-9.8C0,28.8-0.3,24.9,0.3,21c0.6-3.9,2.1-7.6,4.4-10.7c2.3-3.2,5.3-5.8,8.8-7.6S20.8,0,24.7,0c4.6,0,9.1,1.3,13,3.7c3.9,2.4,7.1,5.9,9.1,10c2,4.1,2.9,8.7,2.4,13.3C48.8,31.6,47.1,36,44.3,39.6z M26.5,42.7c4.2-0.4,8.1-2.3,11-5.2c1.7-1.7,3-3.7,3.9-5.9c0.9-2.2,1.4-4.5,1.4-6.9c0-4.2-1.4-8.2-4.1-11.5C36,10,32.3,7.7,28.2,6.9c-4.1-0.8-8.4-0.2-12.1,1.8c-3.7,2-6.6,5.2-8.2,9c-1.6,3.9-1.8,8.2-0.6,12.2s3.8,7.5,7.3,9.8C18.1,42,22.3,43.1,26.5,42.7z M26.7,23.4h5c0.4,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2c0,0.4-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.5-1.2,0.5h-5v5.1c0,0.4-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.5-1.1,0.5c-0.4,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.5-0.7-0.5-1.1v-5.1h-5c-0.4,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.7-0.5-1.2c0-0.4,0.2-0.9,0.5-1.2c0.3-0.3,0.7-0.5,1.2-0.5h5v-5.1c0-0.4,0.2-0.8,0.5-1.1c0.3-0.3,0.7-0.5,1.1-0.5c0.4,0,0.8,0.2,1.1,0.5c0.3,0.3,0.5,0.7,0.5,1.1V23.4z',
	},
	scroll: {
		width: 16,
		height: 22,
		path: 'M8.78781 0.323529C8.62531 0.123329 8.325 0 8 0C7.675 0 7.37468 0.123329 7.21218 0.323529L0.121875 9.05882C-0.040625 9.25902 -0.040625 9.50568 0.121875 9.70588C0.284375 9.90608 0.584687 10.0294 0.909687 10.0294H15.0903C15.4153 10.0294 15.7156 9.90608 15.8781 9.70588C16.0406 9.50568 16.0406 9.25902 15.8781 9.05882L8.78781 0.323529ZM8 1.94118L13.5147 8.73529H2.48531L8 1.94118ZM7.21229 21.6765C7.37479 21.8767 7.67511 22 8.00011 22C8.32511 22 8.62542 21.8767 8.78792 21.6765L15.8782 12.9412C16.0407 12.741 16.0407 12.4943 15.8782 12.2941C15.7157 12.0939 15.4154 11.9706 15.0904 11.9706L0.909801 11.9706C0.584801 11.9706 0.284489 12.0939 0.121989 12.2941C-0.0405109 12.4943 -0.040511 12.741 0.121988 12.9412L7.21229 21.6765ZM2.48543 13.2647L13.5148 13.2647L8.00011 20.0588L2.48543 13.2647Z',
	},
	prev: {
		width: 22,
		height: 22,
		path: 'M13,0.5c0.8,0,1.5,0.3,2.1,0.9c1.2,1.2,1.2,3.1,0,4.2L9.7,11l5.4,5.4c1.2,1.2,1.2,3.1,0,4.2c-1.2,1.2-3.1,1.2-4.2,0l-7.5-7.5c-1.2-1.2-1.2-3.1,0-4.2l7.5-7.5C11.5,0.8,12.2,0.5,13,0.5z',
	},
	next: {
		width: 22,
		height: 22,
		path: 'M9,21.5c-0.8,0-1.5-0.3-2.1-0.9c-1.2-1.2-1.2-3.1,0-4.2l5.4-5.4L6.9,5.6c-1.2-1.2-1.2-3.1,0-4.2c1.2-1.2,3.1-1.2,4.2,0l7.5,7.5c1.2,1.2,1.2,3.1,0,4.2l-7.5,7.5C10.5,21.2,9.8,21.5,9,21.5z',
	},
	deviceRotate: {
		width: 64,
		height: 64,
		path: 'M29.2,2.8c-2.5-2.7-6.6-2.8-9.3-0.3L2.4,19.9c-2.5,2.7-2.3,6.8,0.3,9.3l32,32c1.3,1.3,3.1,2.1,5,2.1c1.6,0,3.2-0.6,4.4-1.8l17.4-17.4c2.5-2.7,2.3-6.8-0.3-9.3L29.2,2.8z M59.5,42.1L42,59.5c-1.5,1.3-3.8,1.2-5.1-0.3l-32-32c-1.5-1.3-1.6-3.6-0.3-5.1L22,4.6c0.6-0.6,1.4-0.9,2.3-0.9c1.1,0,2.1,0.5,2.9,1.2l32,32C60.6,38.3,60.8,40.6,59.5,42.1z M46,46.1c-0.9,0.9-0.9,2.4,0,3.3c0.9,0.9,2.4,0.9,3.3,0s0.9-2.4,0-3.3c0,0,0,0,0,0C48.4,45.2,46.9,45.2,46,46.1C46,46.1,46,46.1,46,46.1z M63,20.4c-0.7-0.4-1.6-0.1-2,0.6l-0.4,0.8C59.2,10.4,50,1.5,38.5,0.5c-0.8-0.1-1.5,0.5-1.6,1.3s0.5,1.5,1.3,1.6l0,0c10.1,0.9,18.2,8.7,19.4,18.8L57,21.8c-0.6-0.5-1.6-0.4-2.1,0.2c-0.5,0.6-0.4,1.6,0.2,2.1l3.9,3.3h0c0,0,0,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0s0,0,0,0c0.1,0,0.2,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0h0c0.1,0,0.2,0,0.3,0l0.1,0c0.1,0,0.2,0,0.3-0.1l0,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0,0,0,0s0,0,0,0c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0v0l2.3-4.4C64,21.7,63.8,20.8,63,20.4C63,20.4,63,20.4,63,20.4z M25.8,60.5C15.6,59.7,7.5,51.8,6.3,41.7L7,42.2c0.6,0.5,1.6,0.4,2.1-0.2c0.5-0.6,0.4-1.6-0.2-2.1l-3.9-3.3h0c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1h0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0-0.1,0c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1c-0.1,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0.1l0,0c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1v0l-2.3,4.4c-0.4,0.7-0.1,1.6,0.6,2s1.6,0.1,2-0.6c0,0,0,0,0-0.1l0.4-0.8C4.8,53.6,14,62.5,25.5,63.5c0,0,0.1,0,0.1,0c0.8,0,1.5-0.7,1.5-1.5C27.1,61.2,26.5,60.6,25.8,60.5L25.8,60.5z',
	},
};

export default {
	props: {
		type: {
			type: String,
			required: true,
		},
	},
	render( createElement ) {
		const {
			width = 16,
			height = 16,
			path,
			paths,
		} = PATHS[ this.type ] || {};

		const children = [];
		if ( path ) {
			children.push( createElement( 'path', {
				attrs: {
					d: path || '',
				},
			} ) );
		} else
		if ( paths ) {
			if ( paths instanceof Array ) {
				paths.forEach( ( d ) => {
					children.push( createElement( 'path', {
						attrs: { d },
					} ) );
				} );
			} else {
				for ( const name in paths ) {
					children.push( createElement( 'path', {
						class: name,
						attrs: { d: paths[ name ] },
					} ) );
				}
			}
		}

		return createElement( 'svg', {
			class: `icon ${ this.type }`,
			attrs: {
				'xmlns': 'http://www.w3.org/2000/svg',
				'viewBox': `0 0 ${ width } ${ height }`,
				'aria-hidden': true,
				width,
				height,
			},
		}, children );
	},
};
</script>
