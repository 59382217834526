<template>
	<div class="tab-set">
		<nav class="tab-set__nav">
			<button
				v-for="tab in tabs"
				:key="tab.name"
				:class="{
					'is-current': tab === currentTab,
				}"
				@click="activateTab( tab.name )"
			>
				{{ tab.title }}
			</button>
		</nav>
		<Transition mode="out-in">
			<Component
				:is="currentTab.component"
				:key="currentTab.name"
				v-bind="currentTab.props"
				class="tab"
			/>
		</Transition>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	inject: [ 'parentView' ],
	props: {
		tabs: {
			type: Array,
			required: true,
		},
	},
	computed: {
		...mapState( {
			currentTab( state ) {
				return this.tabs.find( t => t.name === state.currentTab ) || this.tabs[ 0 ];
			},
		} ),
	},
	methods: {
		activateTab( name ) {
			this.$sounds.play( 'click' );
			this.$log.event( {
				event_type: 'click',
				target: name,
				location: this.parentView,
			} );
			this.goToTab( name );
		}
	},
};
</script>

<style lang="scss">
.tab-set {
	&__nav {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: rem(30);

		button {
			font-weight: $font-weight-black;
			margin: 0 rem(24) rem(10) 0;
			opacity: .4;
			transition: opacity .4s;

			&.is-current {
				opacity: 1;
			}
		}
	}
}

.tab {
	&.v-enter-active,
	&.v-leave-active {
		transition: opacity .4s, visibility .4s;
	}

	&.v-enter,
	&.v-leave-to {
		opacity: 0;
		visibility: hidden;
	}
}
</style>
