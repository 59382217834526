<template>
	<AppScreen
		:name="id"
		class="gamescreen"
		:transition="{
			name: 'build',
			css: false,
			duration: 2000,
		}"
		aria-live="polite"
		@toggle="toggleActive"
	>
		<Transition
			name="build"
			type="animation"
			:duration="1000"
			mode="out-in"
		>
			<div
				v-if="! hasResult"
				key="question"
				class="gamescreen__inner gamescreen__question"
			>
				<Component
					:is="uiComponent"
					key="question"
					:title="title"
					:content="text"
					:is-active="isActive"
					:is-repeat="isRepeat"
					:is-skippable="skippable"
					v-bind="control"
					@answer="logAnswer"
				/>
			</div>
			<div
				v-else
				key="result"
				class="gamescreen__inner gamescreen__result"
			>
				<div class="gamescreen__header">
					<h2
						v-content="resultTitle"
						class="gamescreen__title"
					/>

					<div
						v-content="correct_text"
						class="gamescreen__details"
					/>

					<AppButton
						name="read-more"
						:question="name"
						is-tertiary
						@click="showModal( moreModalId )"
					>
						{{ $l10n( 'read-more' ) }}
					</AppButton>
				</div>

				<div
					:class="[
						'gamescreen__graphic',
						`gamescreen__graphic--${ result }`
					]"
					aria-hidden="true"
				>
					<AppCharacter
						v-if="result === 'timeout'"
						id="lights"
						animation="andon"
					/>
					<AppCharacter
						v-else
						type="answer"
						:color="avatar.name"
						animation="reaction"
						:clip="animation"
					/>
				</div>

				<div class="gamescreen__footer">
					<template v-if="result === 'timeout' && offerTimers">
						<AppButton
							v-if="useTimers"
							class="disable-timer"
							name="disable-timers"
							:question="name"
							icon="stopwatch"
							with-text
							@click="toggleTimers( false )"
						>
							{{ $l10n( 'disable-timer' ) }}
						</AppButton>
						<AppButton
							v-else
							is-secondary
							class="enable-timer"
							name="enable-timers"
							:question="name"
							icon="stopwatch"
							with-text
							@click="toggleTimers( true )"
						>
							{{ $l10n( 'enable-timer' ) }}
						</AppButton>
					</template>

					<AppButton
						name="next"
						:question="name"
						is-primary
						@click="$emit( 'done' )"
					>
						{{ $l10n( 'next' ) }}
					</AppButton>
				</div>
			</div>
		</Transition>

		<Portal
			v-if="hasResult"
			:to="id"
		>
			<AnswerDetails
				:id="moreModalId"
				:context="id"
				:preface="correct_text"
				:content="read_more_text"
				:src="source"
				:source-url="source_url"
				:offer-correction="offerCorrection"
			/>
			<CorrectionForm
				v-if="offerCorrection"
				:id="correctionModalId"
				:explanation="correct_text"
				@submit="submitCorrection"
			/>
		</Portal>
	</AppScreen>
</template>

<script>
/* eslint-disable vue/prop-name-casing */

import { mapState, mapGetters, mapMutations } from 'vuex';

import ButtonsQuestion from './ButtonsQuestion';
import SliderQuestion from './SliderQuestion';
import DraggableQuestion from './DraggableQuestion';
import ReminderQuestion from './ReminderQuestion';

const QUESTION_TYPES = {
	draggable: DraggableQuestion,
	buttons: ButtonsQuestion,
	slider: SliderQuestion,
};

import AnswerDetails from './AnswerDetails';
import CorrectionForm from './CorrectionForm';

const correctClips = [ 'happy', 'love', 'ohh', 'reward' ];
function randomCorrect() {
	return correctClips[ Math.floor( Math.random() * correctClips.length ) ];
}

const incorrectClips = [ 'surprise', 'woah', 'hmm' ];
function randomIncorrect() {
	return incorrectClips[ Math.floor( Math.random() * incorrectClips.length ) ];
}

export default {
	components: {
		AnswerDetails,
		CorrectionForm,
	},
	inject: [ 'offerTimers' ],
	props: {
		id: {
			type: String,
			required: true,
		},
		name: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		text: {
			type: String,
			required: true,
		},
		correct_text: {
			type: String,
			default: '',
		},
		read_more_text: {
			type: String,
			default: '',
		},
		source: {
			type: String,
			default: '',
		},
		source_url: {
			type: String,
			default: '',
		},
		control: {
			type: Object,
			required: true,
		},
		answer: {
			type: String,
			default: null,
		},
		isRepeat: Boolean,
		affectsScore: {
			type: Boolean,
			default: true,
		},
		offerCorrection: Boolean,
		skippable: Boolean,
	},
	data() {
		let uiComponent = QUESTION_TYPES[ this.control.type ];
		if ( this.isRepeat ) {
			uiComponent = ReminderQuestion;
		}

		return {
			startTime: Date.now(),
			uiComponent,
			moreModalId: this.id + '-more',
			correctionModalId: this.id + '-correction',
		};
	},
	computed: {
		...mapState( [ 'currentScreen', 'avatar', 'useTimers' ] ),
		...mapGetters( [ 'getAnswer' ] ),

		isActive() {
			return this.currentScreen === this.id;
		},

		hasAnswer() {
			return this.answer !== null;
		},

		result() {
			// Get the stored answer for the question
			const answer = this.getAnswer( this.id );

			return this.getResult( answer );
		},

		resultTitle() {
			if ( this.result === true ) {
				return this.$l10n( 'correct' );
			} else
			if ( this.result === false ) {
				return this.$l10n( 'incorrect' );
			}

			return this.$l10n( 'timed-out' );
		},

		hasResult() {
			return this.result !== null;
		},

		animation() {
			if ( this.result === true ) {
				return randomCorrect();
			}
			if ( this.result === false ) {
				return randomIncorrect();
			}

			return 'default';
		},
	},
	watch: {
		isActive() {
			this.startTime = Date.now();
		},
	},
	methods: {
		...mapMutations( [
			'saveAnswer',
			'changeScore',
			'hideModal',
			'toggleTimers',
		] ),

		toggleActive( isActive ) {
			if ( isActive ) {
				this.startTime = Date.now();
			}
		},

		getResult( answer ) {
			// If timed out, return answer as result
			if ( answer === 'timeout' ) {
				return answer;
			}

			// If abstained, return null
			if ( answer === 'abstain' ) {
				return null;
			}

			// If an answer is required and provided, use the result
			if ( this.hasAnswer && answer !== null ) {
				// Treat an empty answer as an empty result
				if ( answer === '' ) {
					return answer;
				}
				return answer === this.answer;
			}

			return null;
		},

		logAnswer( answer ) {
			const result = this.getResult( answer );
			const isCorrect = result === true;
			const timeSpent = Date.now() - this.startTime;

			this.$emit( 'answer', result, answer );

			this.saveAnswer( {
				question: this.id,
				answer,
				isCorrect,
			} );

			const resultValue = isCorrect ? 'correct' : 'incorrect';

			this.$log.answer( {
				question_type: this.isRepeat ? 'repeat' : this.control.name,
				question_name: this.name,
				answer_text: answer,
				duration_in_seconds: Math.round( timeSpent / 1000 ),
				result: this.hasAnswer ? resultValue : '',
			} );

			// If no result, just advance
			if ( ! this.hasResult ) {
				this.$emit( 'done' );
			} else
			if ( this.affectsScore ) {
				this.changeScore( isCorrect );

				setTimeout( () => this.$sounds.play( resultValue ), 1500 );
			}
		},

		submitCorrection( { source, comments } ) {
			this.$log.correction( {
				question_name: this.name,
				source_url: source,
				comments,
			} );
		},
	},
};
</script>

<style lang="scss">
.gamescreen {
	justify-content: space-between;
	align-items: center;
	text-align: center;
	padding: 0;

	&__inner {
		flex: 1;
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: rem(70) rem(20) rem(20);

		@include breakpoint($min-width: 400) {
			padding-inline: rem(30);
		}
	}

	&__header {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__body {
		flex: 1;
	}

	&__details {
		margin-bottom: 1em;
	}

	&__footer {
		position: absolute;
		z-index: 3;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		justify-content: flex-end;
		padding: 0 rem(20) rem(20);

		> :not(:first-child) {
			margin-left: 1em;
		}
	}

	&__graphic {
		flex: 1;
		margin-top: auto;
		max-height: 220px;
		width: 100%;
		position: relative;

		.character {
			position: absolute;
			top: 40px;
			left: 50%;
			width: rem(240);
			transform: translateX(-50%);
		}

		@include breakpoint($min-width: 400) {
			max-height: 260px;

			.character {
				width: rem(270);
			}
		}

		&--timeout {
			max-height: 500px;

			.character {
				width: rem(200);
			}
		}
	}

	@include breakpoint($min-height: 800) {
		&__header {
			margin-block: auto;
		}
	}
}

.gamescreen__result {
	&.build-enter-active {
		.gamescreen__header {
			animation: slideInLeft .4s ease-out both;
		}
		.gamescreen__graphic,
		.gamescreen__footer {
			animation: slideInBottom .4s ease-out both;
		}
		.gamescreen__graphic {
			animation-delay: .4s;
		}
		.gamescreen__footer {
			animation-delay: .8s;
		}
	}
	&.build-leave-active,
	.build-leave-active & {
		.gamescreen__header {
			animation: slideOutRight .4s ease-out both;
		}
		.gamescreen__graphic,
		.gamescreen__footer {
			animation: slideOutBottom .4s ease-out both;
		}
	}

	.stopwatch {
		.enabled {
			fill: $color-pink;
		}
	}
	.disable-timer .enabled,
	.enable-timer .disabled {
		display: none;
	}
}

.correction-form {
	&__label,
	&__input {
		display: block;
	}

	&__input {
		color: inherit;
		width: 100%;
	}
}
</style>
