var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
		'option-group': true,
		'is-stacked': _vm.isStacked,
		'is-grid': _vm.isStacked && _vm.options.length > 3,
	}},[_c('div',{staticClass:"option-group__inner"},_vm._l((_vm.options),function(option,index){return _c('button',{key:option.value,class:{
				'option': true,
				'is-large': _vm.isLarge,
				'is-small': _vm.isSmall,
				'is-active': _vm.hasSelected( option.value ),
			},style:({ '--index': index + 1 }),on:{"click":function($event){return _vm.toggleOption( option.value )}}},[_c('div',{staticClass:"option__inner"},[(_vm.withGraphics && option.emote)?_c('AppImage',{attrs:{"src":require(("@/assets/img/emotes/" + (_vm.avatar.name) + "-" + (option.emote) + ".jpg"))}}):_vm._e(),_c('span',{staticClass:"option__text"},[_vm._v(" "+_vm._s(option.label || option.value)+" ")])],1)])}),0),(_vm.noneOption)?_c('button',{class:{
			'option': true,
			'is-none': true,
			'is-large': _vm.isLarge,
			'is-small': _vm.isSmall,
			'is-active': _vm.hasSelected( _vm.noneOptionValue ),
		},style:({ '--index': _vm.options.length }),on:{"click":function($event){return _vm.overrideOption( _vm.noneOptionValue )}}},[_c('div',{staticClass:"option__inner"},[_c('span',{staticClass:"option__text"},[_vm._v(" "+_vm._s(_vm.$l10n( 'not-represented' ))+" ")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }