<template>
	<div
		:class="{
			'draggable-item': true,
			[ type ]: true,
			[ `is-color-${ color }` ]: true,
			'animating': isAnimating,
			'dragging': isDragging,
			'in-range': isInRange,
		}"
	>
		<AppButton
			is-plain
			class="draggable-item__holster"
			@click="select"
		>
			{{ label }}
		</AppButton>
		<div
			ref="handle"
			class="draggable-item__handle"
			aria-hidden="true"
		>
			<AppCharacter
				:type="avatar.name"
				:color="color"
				:align="alignment"
				animation="interaction"
				:clip="animation"
				:wiggle="isDragging"
			/>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { gsap } from 'gsap';
import Draggable from 'gsap/Draggable';

gsap.registerPlugin( Draggable );

import checkIntersect from '../utilities/check-intersect';

export default {
	inheritAttrs: false,
	emits: [ 'pickup', 'drag', 'drop' ],
	props: {
		type: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			required: true,
		},
		color: {
			type: String,
			default: '',
		},
		idleClip: {
			type: String,
			default: '',
		},
		target: HTMLElement,
		enabled: {
			type: Boolean,
			default: true,
		},
		margin: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			isAnimating: false,
			isDragging: false,
			isInRange: false,
			isIdling: false,

			alignment: [ 'left', 'top-left', 'bottom-left' ].includes( this.type ) ? 'left' : 'right',
		};
	},
	computed: {
		...mapState( [ 'avatar' ] ),

		animation() {
			if ( this.isDragging ) {
				return 'picked';
			}

			if ( ! this.enabled ) {
				return 'notpicked';
			}

			if ( this.isIdling ) {
				return this.idleClip;
			}

			return null;
		},
	},
	watch: {
		enabled( isEnabled ) {
			if ( this.draggable ) {
				if ( isEnabled ) {
					this.draggable.enable();
				} else {
					this.draggable.disable();
				}
			}
			if ( isEnabled ) {
				this.isIdling = true;
			}
		},
	},
	mounted() {
		const handle = this.$refs.handle;

		const [ drag ] = Draggable.create( handle, {
			zIndexBoost: false,
			onPress: () => {
				this.isDragging = true;
				this.$emit( 'update', this.isDragging, this );
			},
			onRelease: () => {
				this.isDragging = false;
				this.$emit( 'update', this.isDragging, this );
			},
			onDragStart: () => {
				this.$sounds.play( 'pickup' );
				this.$emit( 'pickup' );
			},
			onDrag: () => {
				this.checkIntersect();
				this.$emit( 'drag', this.isInRange, this );
			},
			onDragEnd: () => {
				this.checkIntersect();
				this.$emit( 'drop', this.isInRange, this );
				this.randomIdleDelay();
			},
		} );

		this.draggable = drag;
		setTimeout( () => this.isIdling = true, 500 );
	},
	destroyed() {
		clearTimeout( this.idleTimeout );
	},
	methods: {
		randomIdleDelay() {
			this.isIdling = false;
			this.idleTimeout = setTimeout( () => {
				this.isIdling = true;
			}, 1000 + ( Math.random() * 3000 ) );
		},
		checkIntersect() {
			if ( this.target ) {
				this.isInRange = checkIntersect( this.$refs.handle, this.target, this.margin );
			}
		},
		reset( target ) {
			if ( ! this.draggable ) {
				return;
			}

			let x = 0;
			let y = 0;
			if ( target ) {
				const box = target.getBoundingClientRect();
				const current = this.$refs.handle.getBoundingClientRect();

				x = ( box.left + ( box.width / 2 ) ) - ( current.left + ( current.width / 2 ) );
				y = ( box.top + ( box.height / 2 ) ) - ( current.top + ( current.height / 2 ) );

				x = `+=${ x }`;
				y = `+=${ y }`;
			}

			this.isAnimating = true;
			this.draggable.disable();
			gsap.to( this.$refs.handle, {
				x,
				y,
				ease: 'power2.out',
				duration: .4,
				onComplete: () => {
					this.isAnimating = false;

					this.draggable.update();
					if ( this.enabled ) {
						this.draggable.enable();
					}
				},
			} );
		},
		select() {
			this.isInRange = true;
			this.$emit( 'drop', true, this );
		},
	},
};
</script>

<style lang="scss">
.draggable-item {
	position: relative;

	&__holster {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: rem(16);
		font-weight: $font-weight-medium;
		@include size(74);
		background: rgba(#fff, .4);
		border-radius: 50%;

		.build-leave-active & {
			animation: zoomOut .3s ease-out both;
		}
	}

	&__handle {
		position: absolute;
		top: rem(60);
		display: flex;
		justify-content: center;
		align-items: center;
		@include size(140);

		.left & {
			left: 0;
		}
		.right & {
			right: 0;
		}

		.character {
			position: relative;
			flex: none;
			width: rem(175); // capacitor width * 1.25
			margin: auto;
			transform-origin: center top;
			transition: transform .2s ease-out;

			&.is-capacitor,
			&.is-ceramic {
				top: rem(-16); // align characters to top
			}

			.left & {
				transform: rotate(6deg) scale(1);
			}
			.right & {
				transform: rotate(-6deg) scale(1);
			}
			.in-range & {
				transform: rotate(0deg) scale(.7);
			}
		}

		@include breakpoint($min-height: 600) {
			@include size(160);

			.character {
				width: rem(200);
			}
		}
	}
}
</style>
