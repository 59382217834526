<template>
	<AppScreen
		name="avatar"
		class="avatarscreen"
		:transition="{
			name: 'slide-in',
		}"
	>
		<div class="avatarscreen__header">
			<h2 class="avatarscreen__title">
				{{ $l10n( 'get-started' ) }}
				<br /> <strong>{{ $l10n( 'choose-avatar' ) }}</strong>
			</h2>
		</div>
		<div class="avatarscreen__body">
			<BaseCarousel
				with-pagination
				@update="selectAvatar"
			>
				<figure
					v-for="(avatar, index) in avatars"
					:key="avatar.name"
					class="avatar-slide"
				>
					<figcaption class="avatar-slide__caption">
						<h3 class="avatar-slide__label">
							{{ $l10n( avatar.label ) }}
						</h3>
						<p
							v-if="avatar.description"
							class="avatar-slide__description"
						>
							{{ $l10n( avatar.description ) }}
						</p>
						<button
							class="avatar-slide__button"
							@click="selectAvatar( index )"
						>
							<svg
								viewBox="0 0 93 93"
								class="avatar-slide__checkmark"
								aria-hidden="true"
							>
								<circle
									cx="46.5"
									cy="46.5"
									r="42.5"
								/>
								<polyline points="63.2,37.2 40.3,60 27.9,47.6" />
							</svg>
							<ScreenReaderText>{{ `Select ${ avatar.label }` }}</ScreenReaderText>
						</button>
					</figcaption>
					<AppImage
						:src="require(`@/assets/img/avatar-${ avatar.name }.jpg`)"
						width="270"
						height="220"
					/>
				</figure>
			</BaseCarousel>
		</div>
		<div class="avatarscreen__footer">
			<AppButton
				name="start"
				location="avatar"
				is-primary
				@click="confirm"
			>
				{{ $l10n( 'select-avatar' ) }}
			</AppButton>
		</div>
	</AppScreen>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import preloadImages from '@/utilities/preload-images.js';

import { avatars } from '../store';
import BaseCarousel from './BaseCarousel';

export default {
	components: {
		BaseCarousel,
	},
	data() {
		return {
			avatars,
		};
	},
	computed: mapState( [ 'avatar' ] ),
	methods: {
		...mapMutations( [ 'setAvatar' ] ),

		selectAvatar( index ) {
			this.setAvatar( this.avatars[ index ] );
		},

		confirm() {
			preloadImages( this.avatar.name );
			this.$emit( 'next' );
		},
	},
};
</script>

<style lang="scss">
.avatarscreen {
	justify-content: space-between;
	align-items: center;
	text-align: center;
	padding-inline: 0;

	&__header,
	&__footer {
		position: relative;
		z-index: 1;
		padding-inline: rem(30);
	}

	&__title {
		font-weight: $font-weight-medium;
	}

	&__body {
		width: auto;
		max-width: none;
	}

	&__footer {
		width: auto;
	}

	.carousel {
		display: flex;
		flex-direction: column;
		margin-top: 0;

		&__pagination {
			order: 1;
			margin-top: rem(16);
		}
	}

	@include breakpoint($max-height: 550) {
		.carousel {
			margin-bottom: rem(10);

			&__pagination {
				margin-top: rem(10);
			}
		}
	}

	@include breakpoint($min-height: 700) {
		&__pagination {
			margin-top: rem(24);
		}
	}

	@include breakpoint($min-height: 800) {
		&__header {
			margin-top: auto;
		}
		&__body {
			margin-bottom: auto;
		}
	}
}

.avatar-slide {
	position: relative;
	max-width: rem(330);
	height: rem(300);
	background: $color-white;
	border-radius: rem(24);
	box-shadow: 0 6px 0 rgba($color-black, .25);
	overflow: hidden;
	margin: 0 auto 6px;

	&__caption {
		position: relative;
		z-index: 1;
		flex: none;
		padding: rem(36) rem(40) 0;
	}
		&__label {
			font-size: rem(24);
			font-weight: $font-weight-medium;
			margin-bottom: 0;
		}

		&__button {
			position: absolute;
			z-index: 1;
			top: rem(12);
			right: rem(12);

			&:focus-visible {
				outline: 1px dashed $color-pink;
			}
		}

		&__checkmark {
			display: block;
			@include size(40);

			circle {
				fill: none;
				stroke: $color-black;
				stroke-width: 7;
				opacity: .2;
				transition: opacity .2s;
			}

			polyline {
				fill: none;
				stroke: $color-pink;
				stroke-width: 12;
				stroke-linecap: round;
				stroke-linejoin: round;
				opacity: 0;
				transition: opacity .2s;
			}

			.is-current-slide & {
				circle {
					opacity: 1;
				}
				polyline {
					opacity: 1;
				}
			}
		}

	.image {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		width: 94%;
		max-width: rem(240);
		height: auto;
		margin: auto;
		animation: slideOutBottom .4s ease-out both;

		.is-current-slide & {
			animation: slideInBottom .4s .2s ease-out both;
		}
	}

	@include breakpoint($max-height: 550) {
		height: rem(220);

		.image {
			max-width: rem(160);
		}
	}

	@include breakpoint($min-height: 700) {
		height: rem(340);

		&__caption {
			padding: rem(46) rem(40) 0;
		}

		.image {
			max-width: rem(270);
		}
	}

	@include breakpoint($min-width: 768) {
		.image {
			animation: none !important;
		}
	}
}
</style>
