<template functional>
	<!-- carry over any attributes, expecially classes -->
	<div
		v-bind="data.attrs"
		:class="[ 'star', data.class, data.staticClass ]"
	>
		<svg class="star__icon" viewBox="0 0 96 92">
			<path d="M42.8,6.2c2.3-4,8.1-4,10.4,0l10.5,18c0.8,1.5,2.3,2.5,3.9,2.8l20.3,4.4c4.5,1,6.3,6.4,3.2,9.9L77.3,56.8c-1.1,1.3-1.7,2.9-1.5,4.6l2.1,20.7c0.5,4.6-4.2,8-8.4,6.1l-19-8.4c-1.5-0.7-3.3-0.7-4.8,0l-19,8.4c-4.2,1.9-8.9-1.5-8.4-6.1l2.1-20.7c0.2-1.7-0.4-3.3-1.5-4.6L4.9,41.3c-3.1-3.4-1.3-8.9,3.2-9.9L28.4,27c1.6-0.4,3.1-1.4,3.9-2.8L42.8,6.2z" />
		</svg>
		<div v-if="level !== null" class="star__label">
			{{ parent.$l10n( props.number ) }}
		</div>
		<slot />
	</div>
</template>

<script>
export default {
	props: {
		number: {
			type: Number,
			default: null,
		},
	},
};
</script>

<style lang="scss">
.star {
	position: relative;
	filter: drop-shadow(0 10px 0 rgba(#000, .16));

	&__icon {
		fill: $color-pink;
		transform-origin: 50% 55%;
	}

	&__label {
		position: absolute;
		inset: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 42px;
		font-weight: $font-weight-heavy;
		line-height: 1;
		padding-top: .25em;
		color: $color-white;
	}
}
</style>
