<template>
	<div class="app-toolbar-wrapper">
		<div class="app-toolbar">
			<div class="button-group">
				<AppButton
					name="menu"
					location="main"
					class="toolbar-button"
					icon="menu"
					is-plain
					@click="showTooltip = false; showModal( 'menu' )"
				>
					{{ $l10n( 'menu' ) }}
				</AppButton>

				<TheSoundButton class="toolbar-button" />
			</div>

			<Transition>
				<AppTooltip
					v-if="showTooltip"
					class="app-toolbar__help"
					@click="showTooltip = false; showModal( 'menu' ); goToTab( 'rewards' )"
				>
					<span v-content="$l10n( 'rewards-here' )" />
				</AppTooltip>
			</Transition>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import TheSoundButton from './TheSoundButton.vue';

export default {
	components: {
		TheSoundButton,
	},
	props: {
		inGame: Boolean,
		byline: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			showTooltip: false,
			didTooltip: false,
		};
	},
	computed: {
		...mapState( [ 'rewards' ] ),
	},
	watch: {
		rewards() {
			if ( ! this.didTooltip ) {
				this.showTooltip = true;
				this.didTooltip = true;
				setTimeout( () => this.showTooltip = false, 3000 );
			}
		},
	},
};
</script>

<style lang="scss">
.app-toolbar {
	position: absolute;
	z-index: 5;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: rem(12);

	.button-group {
		margin: 0;
	}

	&.v-enter-active,
	&.v-leave-active {
		transition: visibility .2s, transform .2s ease-out;
	}

	&.v-enter,
	&.v-leave-to {
		visibility: hidden;
		transform: translateY(-100%);
	}

	&__help {
		top: 100%;
		bottom: auto;
		left: rem(10);
		max-width: rem(140);
		transform: none;
		transform-origin: center top;

		&::after {
			bottom: 100%;
			top: auto;
			left: 1.8em;
			margin-bottom: -5px;
			border-top-color: transparent;
			border-bottom-color: white;
		}
	}
}

.toolbar-button {
	path {
		fill: $color-dark;
	}
}
</style>
