<template>
	<div>
		<h2>{{ $l10n( 'use-music' ) }}</h2>
		<FancyToggle
			v-model="useMusic"
			:label="$l10n( 'use-music' )"
			:on-graphic="require('@/assets/img/music-on.jpg')"
			:off-graphic="require('@/assets/img/music-off.jpg')"
		/>

		<h2>{{ $l10n( 'use-sounds' ) }}</h2>
		<FancyToggle
			v-model="useSounds"
			:label="$l10n( 'use-sounds' )"
			:on-graphic="require('@/assets/img/sounds-on.jpg')"
			:off-graphic="require('@/assets/img/sounds-off.jpg')"
		/>

		<template v-if="offerTimers">
			<h2>{{ $l10n( 'use-timers' ) }}</h2>
			<FancyToggle
				v-model="useTimers"
				:label="$l10n( 'use-timers' )"
				:on-graphic="require('@/assets/img/timers-on.jpg')"
				:off-graphic="require('@/assets/img/timers-off.jpg')"
			/>
		</template>
	</div>
</template>

<script>
import FancyToggle from './FancyToggle.vue';

export default {
	components: {
		FancyToggle,
	},
	inject:[ 'offerTimers' ],
	computed: {
		useMusic: {
			get() {
				return this.$store.state.useMusic;
			},
			set( value ) {
				this.$store.commit( 'toggleMusic', value );
			},
		},
		useSounds: {
			get() {
				return this.$store.state.useSounds;
			},
			set( value ) {
				this.$store.commit( 'toggleSounds', value );
			},
		},
		useTimers: {
			get() {
				return this.$store.state.useTimers;
			},
			set( value ) {
				this.$store.commit( 'toggleTimers', value );
			},
		},
	},
};
</script>
