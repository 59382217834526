var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModal',{staticClass:"menu",attrs:{"name":"menu"}},[_c('AppTabSet',{attrs:{"tabs":[
			{
				name: 'rewards',
				title: _vm.$l10n( 'rewards' ),
				component: _vm.TheRewardsTab,
			},
			{
				name: 'settings',
				title: _vm.$l10n( 'settings' ),
				component: _vm.TheSettingsTab,
			},
			{
				name: 'about',
				title: _vm.$l10n( 'about' ),
				component: _vm.TheAboutTab,
				props: {
					about: _vm.about,
					privacyPolicy: _vm.privacyPolicy,
					termsOfUse: _vm.termsOfUse,
				},
			} ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }