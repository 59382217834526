<template>
	<AppScreen
		ref="screen"
		name="demosurvey"
		class="demosurvey demosurvey--intro"
		:transition="{
			name: 'build',
			duration: 2000,
		}"
		@show="setupScrollable"
	>
		<div
			ref="scrollable"
			class="demosurvey__body"
		>
			<h2 class="demosurvey__title">
				{{ $l10n( 'demosurvey-why' ) }}
			</h2>
			<slot />
		</div>
		<div class="demosurvey__footer">
			<AppButton
				v-if="isScrollable"
				:disabled="doneScrolling"
				icon="scroll"
				with-text
				@click="scrollDown"
			>
				{{ $l10n( 'scroll-down' ) }}
			</AppButton>
			<AppButton
				is-primary
				:disabled="! doneScrolling"
				@click="$emit( 'submit' )"
			>
				{{ $l10n( 'continue' ) }}
			</AppButton>
		</div>
	</AppScreen>
</template>

<script>
export default {
	emits: [ 'submit' ],
	data() {
		return {
			isScrollable: false,
			doneScrolling: false,
		};
	},
	mounted() {
		this.resizeCheck = () => {
			const { scrollable } = this.$refs;

			this.isScrollable = scrollable.scrollHeight > scrollable.offsetHeight;
			this.doneScrolling = ! this.isScrollable;
		};
	},
	destroyed() {
		window.removeEventListener( 'resize', this.resizeCheck );
	},
	methods: {
		setupScrollable() {
			this.$nextTick( () => {
				if ( this.$refs.scrollable ) {
					const { scrollable } = this.$refs;

					this.isScrollable = scrollable.scrollHeight > scrollable.offsetHeight;
					this.doneScrolling = ! this.isScrollable;

					window.addEventListener( 'resize', this.resizeCheck );

					scrollable.addEventListener( 'scroll', ( { target } ) => {
						this.doneScrolling = target.scrollHeight <= target.offsetHeight + target.scrollTop + 20; // trigger a bit early
					} );
				}
			} );
		},
		scrollDown() {
			this.$refs.scrollable.scrollTop += window.innerHeight / 2;
		},
	},
};
</script>

<style lang="scss">
.demosurvey--intro {
	overflow: hidden;
	padding: 0 rem(30);

	.demosurvey__body {
		justify-content: flex-start;
		flex: 0 1 100%;
		text-align: left;
		overflow: auto;
		scroll-behavior: smooth;
		padding-top: rem(70);
		padding-bottom: rem(80);
	}
}
</style>
