<template>
	<AppScreen
		name="levelChange"
		:class="{
			'levelscreen': true,
			'is-change-up': isLevelUp,
			'is-change-down': ! isLevelUp,
			[ 'is-level-' + ( level.index ) ]: true,
			'show-recovery': showRecover,
		}"
		:transition="{
			name: 'build',
			duration: 3000,
		}"
		@toggle="toggleActive"
	>
		<div class="levelscreen__header">
			<h2>{{ title }}</h2>
			<p
				v-content="text"
				class="lead"
			/>

			<LevelBar
				:old-level="oldLevel"
				:new-level="newLevel"
				:animate="isLevelUp ? 'up' : 'down'"
			/>

			<div
				v-if="! isLevelUp"
				class="button-group is-stacked"
			>
				<AppButton
					v-if="offerRecover"
					name="recover"
					:location="level.name"
					is-primary
					@click="$emit( 'recover' )"
				>
					{{ $l10n( 'recover-level' ) }}
				</AppButton>

				<AppButton
					name="skip-recover"
					:location="level.name"
					@click="$emit( 'done' )"
				>
					{{ $l10n( offerRecover ? 'skip' : 'next' ) }}
				</AppButton>
			</div>
		</div>

		<div
			class="levelscreen__graphic"
			aria-hidden="true"
		>
			<AppCharacter
				type="answer"
				:color="avatar.name"
				animation="reaction"
				:clip="animation"
			/>
		</div>

		<div
			v-if="isLevelUp"
			class="levelscreen__footer"
		>
			<template v-if="didRecover">
				<AppButton
					name="continue"
					:location="level.name"
					is-primary
					@click="$emit( 'done' )"
				>
					{{ $l10n( 'next' ) }}
				</AppButton>
			</template>
			<template v-else>
				<AppButton
					name="skip"
					:location="level.name"
					@click="$emit( 'done' )"
				>
					{{ $l10n( 'skip-reward' ) }}
				</AppButton>
				<AppButton
					name="continue"
					:location="level.name"
					is-primary
					@click="$emit( 'claim' )"
				>
					{{ $l10n( 'next' ) }}
				</AppButton>
			</template>
		</div>
	</AppScreen>
</template>

<script>
import { mapState } from 'vuex';
import LevelBar from './LevelBar';

export default {
	components: {
		LevelBar,
	},
	props: {
		level: {
			type: Number,
			required: true,
		},
		offerRecover: {
			type: Boolean,
			required: true,
		},
		didRecover: Boolean,
		recoverNotice: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			isActive: false,
			isLevelUp: false,
			showRecover: false,
		};
	},
	computed: {
		...mapState( [ 'avatar' ] ),

		title() {
			if ( this.didRecover ) {
				return this.$l10n( 'level-recover' );
			} else
			if ( this.isLevelUp ) {
				return this.$l10n( 'level-up' );
			}

			return this.$l10n( 'level-down' );
		},
		text() {
			let string = 'level-down-detail';
			if ( this.didRecover ) {
				string = 'level-recover-detail';
			} else
			if ( this.isLevelUp ) {
				string = 'level-up-detail';
			}

			return this.$l10n( string, `<strong>${ this.level }</strong>` );
		},
		oldLevel() {
			return this.level - ( this.isLevelUp ? 1 : 0 );
		},
		newLevel() {
			return this.level + ( this.isLevelUp ? 0 : 1 );
		},
		animation() {
			if ( this.isLevelUp ) {
				return Math.random() > 0.5 ? 'reward' : 'love';
			}

			return 'surprise';
		},
	},
	watch: {
		level( newLevel, oldLevel ) {
			this.isLevelUp = newLevel > oldLevel;
		},
	},
	methods: {
		toggleActive( isActive ) {
			this.isActive = isActive;

			if ( this.isActive ) {
				setTimeout( () => this.$sounds.play( this.isLevelUp ? 'level-up' : 'level-down' ), 1000 );
				this.showRecover = false;
			}
		},
		maybeOfferRecovery() {
			if ( ! this.isLevelUp && this.offerRecover && ! this.showRecover ) {
				this.showRecover = true;
			} else {
				this.$emit( 'done' );
			}
		},
	},
};
</script>

<style lang="scss">
.levelscreen {
	justify-content: flex-start;
	align-items: stretch;
	text-align: center;

	&__header {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 1em;

		.build-enter-active & {
			animation: slideInLeft .4s ease-out both;
			animation-delay: .4s;
		}
		.build-leave-active & {
			animation: slideOutRight .4s ease-out both;
		}
	}

	&.build-enter-active {
		.levelbar {
			animation: slideInBottom .4s ease-out both;
			animation-delay: .8s;
		}
		.button-group {
			animation: slideInLeft .4s ease-out both;
			animation-delay: 1.4s;
		}
	}

	&__graphic {
		position: relative;
		flex: 1;
		margin-top: auto;
		max-height: 220px;

		.character {
			position: absolute;
			top: 0;
			left: 50%;
			width: rem(240);
			transform: translateX(-50%);
		}

		@include breakpoint($min-width: 400) {
			max-height: 260px;

			.character {
				width: rem(270);
			}
		}

		.build-enter-active & {
			animation: slideInBottom .4s ease-out both;
			animation-delay: 2s;
		}
		.build-leave-active & {
			animation: slideOutBottom .4s ease-out both;
		}
	}

	&__footer {
		position: absolute;
		z-index: 3;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		justify-content: flex-end;
		padding: 0 rem(20) rem(20);

		> :not(:first-child) {
			margin-left: 1em;
		}

		.build-enter-active & {
			animation: slideInBottom .4s ease-out both;
			animation-delay: 2.6s;
		}
		.build-leave-active & {
			animation: slideOutBottom .4s ease-out both;
		}
	}
}
</style>
