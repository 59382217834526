<template>
	<label
		:class="{
			'fancy-toggle': true,
			'is-checked': status,
		}"
	>
		<input
			class="fancy-toggle__input"
			type="checkbox"
			:checked="status"
			@change="$sounds.play( 'click' ), $emit( 'toggle', ! status )"
		/>
		<ScreenReaderText>{{ label }}</ScreenReaderText>
		<span
			class="fancy-toggle__graphic"
			aria-hidden="true"
		>
			<Transition>
				<img
					:key="status ? 'on' : 'off'"
					:src="status ? onGraphic : offGraphic"
					alt=""
				/>
			</Transition>
		</span>
		<span
			class="fancy-toggle__bar"
			aria-hidden="true"
		>
			<span class="fancy-toggle__indicator">
				{{ $l10n( status ? 'on' : 'off' ) }}
			</span>
		</span>
	</label>
</template>

<script>
export default {
	model: {
		prop: 'status',
		event: 'toggle',
	},
	props: {
		status: Boolean,
		label: {
			type: String,
			required: true,
		},
		onGraphic: {
			type: String,
			default: null,
		},
		offGraphic: {
			type: String,
			default: null,
		},
	},
};
</script>

<style lang="scss">
.fancy-toggle {
	position: relative;
	display: flex;
	align-items: flex-end;
	width: 100%;
	background: $color-light;
	border-radius: rem(90);
	margin-block: rem(16) rem(40);
	overflow: hidden;
	transform: translateZ(0); // fix crop during fade in safari

	&__input {
		appearance: none;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
	}

	&__graphic {
		flex: none;
		position: relative;
		width: rem(150);
		height: rem(90);

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			&.v-enter-active,
			&.v-leave-active {
				transition: opacity .4s linear;
			}

			&.v-enter,
			&.v-leave-to {
				opacity: 0;
			}
		}
	}

	&__bar {
		flex: 1;
		position: relative;
		background: $color-white;
		height: rem(70);
		border-radius: rem(70);
		margin: rem(12);
	}
		&__indicator {
			position: absolute;
			top: 0;
			left: calc(100% - #{rem(70)});
			display: flex;
			justify-content: center;
			align-items: center;
			@include size(70px);
			font-size: rem(20);
			font-weight: $font-weight-medium;
			background: $color-black;
			color: $color-white;
			border-radius: 50%;
			box-shadow: 0 6px 0 rgba(#000, .2);
			transition: .4s ease-out;
			transition-property: color, background, left;

			.is-checked & {
				left: 0;
				left: calc(0% - 0rem); // 0%-0rem beacause safari needs shit spelled out
				background: $color-green;
				color: $color-black;
			}
		}

	&__input:focus-visible ~ &__bar &__indicator {
		box-shadow: 0 0 0 4px $color-pink;
	}
}
</style>
