const IMAGES = {
	block: [
		require( '@/assets/img/characters/answer-block.webp' ),
		require( '@/assets/img/characters/block-blue.webp' ),
		require( '@/assets/img/characters/block-green.webp' ),
		require( '@/assets/img/characters/block-magenta.webp' ),
		require( '@/assets/img/characters/block-purple.webp' ),
		require( '@/assets/img/characters/block-teal.webp' ),
		require( '@/assets/img/characters/block-yellow.webp' ),
	],
	capacitor: [
		require( '@/assets/img/characters/answer-capacitor.webp' ),
		require( '@/assets/img/characters/capacitor-blue.webp' ),
		require( '@/assets/img/characters/capacitor-green.webp' ),
		require( '@/assets/img/characters/capacitor-magenta.webp' ),
		require( '@/assets/img/characters/capacitor-purple.webp' ),
		require( '@/assets/img/characters/capacitor-teal.webp' ),
		require( '@/assets/img/characters/capacitor-yellow.webp' ),
	],
	ceramic: [
		require( '@/assets/img/characters/answer-ceramic.webp' ),
		require( '@/assets/img/characters/ceramic-blue.webp' ),
		require( '@/assets/img/characters/ceramic-green.webp' ),
		require( '@/assets/img/characters/ceramic-magenta.webp' ),
		require( '@/assets/img/characters/ceramic-purple.webp' ),
		require( '@/assets/img/characters/ceramic-teal.webp' ),
		require( '@/assets/img/characters/ceramic-yellow.webp' ),
	],
};

function fetchImage( path ) {
	const img = new Image();
	img.src = path;
}

export default function preloadImages( prefix ) {
	[
		...( IMAGES[ prefix ] || [] ),
		require( '@/assets/img/characters/cloud.webp' ),
		require( '@/assets/img/characters/lights.webp' ),
	].forEach( fetchImage );
}
