<template>
	<div class="reminder-question">
		<div class="reminder-question__header">
			<h2>{{ $l10n( 'remember-question' ) }}</h2>
			<div v-html="content" />
		</div>

		<div class="reminder-question__body">
			<div
				class="reminder-question__graphic"
				aria-hidden="true"
			>
				<AppCharacter
					type="cloud"
					animation="cloud"
				/>
			</div>

			<OptionButtons
				:options="options"
				is-large
				@change="$emit( 'answer', $event )"
			/>
		</div>

		<div
			v-if="isSkippable"
			class="reminder-question__footer"
		>
			<AppButton
				is-circular
				@click="$emit( 'answer', 'abstain' )"
			>
				{{ $l10n( 'skip' ) }}
			</AppButton>
		</div>
	</div>
</template>

<script>
import OptionButtons from './OptionButtons.vue';

export default {
	components: {
		OptionButtons,
	},
	inheritAttrs: false,
	props: {
		title: {
			type: String,
			required: true,
		},
		content: {
			type: String,
			required: true,
		},
		values: {
			type: Array,
			required: true,
		},
		labels: {
			type: Array,
			required: true,
		},
		timer: {
			type: Number,
			default: 0,
		},
		isSkippable: Boolean,
	},
	data() {
		const options = [];
		const optionCount = Math.min( this.values.length, this.labels.length );

		for ( let i = 0; i < optionCount; i++ ) {
			options.push( {
				value: this.values[ i ],
				label: this.labels[ i ],
			} );
		}

		return {
			options,
		};
	},
};
</script>

<style lang="scss">
.reminder-question {
	position: relative;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	text-align: center;

	&__header {
		.build-enter-active & {
			animation: slideInLeft .4s ease-out both;
		}
		.build-leave-active & {
			animation: slideOutRight .4s ease-out both;
		}
	}

	&__body {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;

		.option-group {
			position: relative;
			z-index: 1;
			margin-bottom: auto;
		}
	}
		&__graphic {
			position: relative;
			z-index: 0;
			width: 100%;
			height: 200px;
			margin-top: auto;

			.character {
				position: absolute;
				width: 100vw;
				max-width: rem(420);
				top: 0;
				left: 50%;
				transform: translateX(-50%);
			}

			.build-enter-active & {
				animation: slideInLeft .4s ease-out both;
				animation-delay: .4s;
			}
			.build-leave-active & {
				animation: slideOutRight .4s ease-out both;
			}

			@include breakpoint($min-height: 700) {
				height: 240px;
			}
		}

	&__footer {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: auto;

		.build-enter-active & {
			animation: slideInBottom .4s ease-out both;
			animation-delay: .6s;
		}
		.build-leave-active & {
			animation: slideOutBottom .4s ease-out both;
		}
	}
}
</style>
