<template>
	<div class="draggable-question">
		<div
			class="draggable-question__header subheading"
			v-html="content"
		/>
		<div
			:class="{
				'draggable-question__body': true,
				'is-double': options.length <= 2,
				'is-repeat': isRepeat,
			}"
		>
			<div
				ref="dropzone"
				:class="{
					'dropzone': true,
					'is-focused': optionInRange,
				}"
				aria-hidden="true"
			>
				<svg
					class="dropzone__shape"
					viewBox="0 0 194 256"
				>
					<path :d="dropzoneShape" />
				</svg>
				<TimerShape
					v-if="useTimers"
					:width="60"
					:height="60"
					:type="isRepeat ? 'cloud' : 'circle'"
					:duration="timer"
					:start="isActive"
					:delay="2"
					:paused="activeModals.length > 0 || isMobileLandscape"
					:animate="activeOption !== false"
					@expire="skipAnswer"
				/>
			</div>

			<div class="draggable-question__options">
				<Transition>
					<AppTooltip
						v-if="showTooltip && ! hasSelected"
						class="draggable-question__help"
					>
						<span v-content="$l10n( 'drag-help' )" />
					</AppTooltip>
				</Transition>
				<DraggableQuestionOption
					v-for="( option, id ) in options"
					:key="id"
					v-bind="option"
					:color="colors[ id ]"
					:target="$refs.dropzone"
					:enabled="isActive && ( activeOption === id || activeOption === false )"
					:margin="0"
					:idle-clip="idleClips[ id ]"
					@pickup="hideTooltip"
					@update="updateActiveOption"
					@drag="updateOptionInRange"
					@drop="validateOptionDrop"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import shuffleArray from 'shuffle-array';

import TimerShape from './TimerShape';
import DraggableQuestionOption from './DraggableQuestionOption';

const SHAPES = {
	capacitor: 'M26.1,33.5C25.5,40.2,29,135.5,29,135.5s4,18.8,3.2,28.4s-2.8,11.2-2.6,21.6c0.2,6.9,1.1,13.7,2.6,20.4c0,0,5.2,6,11,9s23.2,5,23.2,5L67,253c0,0,7.6,2.4,9.8-0.4V221c0,0,26.4,0.2,30.8,0.2l0.2,31.2c0,0,3.2,2.8,10,0.6l0.6-32.2c0,0,30.2-3,36.8-8.2s6.9-7.1,7.7-12.9c0.8-5.8,1.1-15.5,1.1-15.5s-1.5-9.7-2.3-17.1c-0.6-7.9-0.1-15.8,1.5-23.6c1.3-5.2,2.7-42.6,3.1-58.1c0.4-15.6,1.3-50.1,1.3-50.1s0.5-6.7-4.3-7.7c-4.8-1-52.1-2.6-65.5-2.6s-58.3,1.6-58.3,1.6S26.6,27.2,26.1,33.5z',
	ceramic: 'M55.4,200.9v48.7c0,2.4,2,4.4,4.4,4.4c1.2,0,2.3-0.5,3.1-1.3c0.8-0.8,1.3-1.9,1.3-3.1c0-12.5,0-47.6,0-47.6c4.1,0,8.6-0.6,13.6-2.2c10.5-3.2,23.1,2.4,31.4,5.3c0.9,0.3,1.9,0.6,3,0.7c0,0,0,31.8,0,43.7c0,1.2,0.5,2.3,1.3,3.1c0.8,0.8,1.9,1.3,3.1,1.3c2.4,0,4.4-2,4.4-4.4c0-12,0-44.2,0-44.2c6.7-1.4,14.2-4.9,20.7-9.3c0,0,49.7-32.9,50.2-88.9c0.5-51.4-40.8-92.5-75.5-95.5C74.7,8.1,21.3,6.8,13.7,46.3C8,75.4,7.3,69.6,3.7,84.6c-3.6,15-5.1,56.1,30.6,100.2C34.4,184.8,40.7,197.1,55.4,200.9z',
	block: 'M158.5,210.4c8.7-33.4,6.4-139.8,4.8-151.8c0,0,6.8-23.1,4.8-40.7c-0.1-0.6-1.6-10.5-18.4-12.6C133.1,3.3,68.2-0.2,41,4c-11.5,1.8-13.3,6-14.1,13c-0.8,7.1-3.2,34.5,1.2,37.8c0,0-4.8,145.2,1.9,152.2c0,0-7,28-0.4,35.9c5.6,6.6,16.6,8.7,23,9.2c11.4,0.9,80.8,1.9,80.8,1.9s19.2,1,24.6-11.1C163,231.9,161.1,221.6,158.5,210.4z',
};

const TYPES = [ 'left', 'right' ];
const IDLE_CLIPS = [ 'floss', 'pickme', 'eyes' ];

function shuffle( array ) {
	return shuffleArray( array, { copy: true } );
}

export default {
	components: {
		TimerShape,
		DraggableQuestionOption,
	},
	inheritAttrs: false,
	props: {
		isActive: Boolean,
		isRepeat: Boolean,
		content: {
			type: String,
			required: true,
		},
		values: {
			type: Array,
			required: true,
		},
		labels: {
			type: Array,
			required: true,
		},
		timer: {
			type: Number,
			default: 60,
		},
	},
	data() {
		const options = [];
		const optionCount = Math.min( this.values.length, this.labels.length, TYPES.length );

		for ( let i = 0; i < optionCount; i++ ) {
			options.push( {
				type: TYPES[ i ],
				value: this.values[ i ],
				label: this.labels[ i ],
			} );
		}

		return {
			options,
			colors: [ 'yellow', 'blue' ],
			idleClips: shuffle( IDLE_CLIPS ),
			activeOption: false,
			optionInRange: false,
			showTooltip: false,
			hasSelected: false,
		};
	},
	computed: {
		...mapState( [
			'activeModals',
			'isMobileLandscape',
			'useTimers',
			'avatar',
			'showDragHelper',
		] ),

		dropzoneShape() {
			return SHAPES[ this.avatar.name ];
		},
	},
	mounted() {
		this.tooltipTimeout = setTimeout( () => this.showTooltip = this.showDragHelper, 3000 );
	},
	methods: {
		...mapMutations( [ 'toggleDragHelper' ] ),

		hideTooltip() {
			this.toggleDragHelper( false );
		},

		skipAnswer() {
			this.activeOption = -1;
			this.handleResult( 'timeout' );
			this.toggleDragHelper( true );
		},
		updateActiveOption( isDragging, hand ) {
			this.showTooltip = false;
			if ( isDragging ) {
				this.activeOption = hand.$vnode.key;
			} else if ( this.activeOption === hand.$vnode.key ) {
				this.activeOption = false;
			}
		},
		updateOptionInRange( inRange ) {
			this.optionInRange = inRange;
		},
		validateOptionDrop( inRange, hand ) {
			if ( inRange ) {
				const result = this.options[ hand.$vnode.key ].value;
				this.handleResult( result );
				this.$sounds.play( 'drop' );
				hand.reset( this.$refs.dropzone );
			} else {
				hand.reset();
			}

			// Pick new idle clips for each
			this.idleClips = shuffle( IDLE_CLIPS );
		},
		handleResult( result ) {
			this.hasSelected = true;
			this.showTooltip = false;

			this.$nextTick( () => {
				this.$emit( 'answer', result );
			} );
		},
	},
};
</script>

<style lang="scss">
@keyframes dropzoneFocus {
	from {
		stroke-dashoffset: 0;
	}
	to {
		stroke-dashoffset: 13;
	}
}

@keyframes dropzoneExpire {
	0%{
		transform: scale(1) rotate(0deg);
	}
	100% {
		transform: scale(0) rotate(360deg);
	}
}

@keyframes dropzoneConfirm {
	0%{
		transform: scale(1) rotate(0deg);
	}
	50% {
		transform: scale(1.5) rotate(0deg);
	}
	100% {
		transform: scale(0) rotate(360deg);
	}
}

.draggable-question {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&__header {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-block: auto;

		.build-enter-active & {
			animation: slideInLeft .4s ease-out both;
			animation-delay: .4s;
		}
		.build-leave-active & {
			animation: slideOutRight .4s ease-out both;
			animation-delay: .4s;
		}

		@media screen and (min-height: #{em(700)}), screen and (min-width: #{em(600)}) and (min-height: #{em(600)}) {
			.is-type-main & {
				font-size: rem(26);
			}
		}

		@media screen and (min-height: #{em(1000)}) {
			.is-type-main & {
				font-size: rem(32);
			}
		}
	}

	&__body {
		position: relative;
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		width: 100%;
		max-width: rem(420);
		max-height: 60vh;
		max-height: calc(50vh + #{rem(120)});
		margin: rem(50) auto 0;

		.build-enter-active & {
			animation: slideInBottom .4s ease-out both;
			animation-delay: .8s;
		}
	}

		.dropzone {
			margin-bottom: rem(-40);

			.build-leave-active & {
				animation: slideOutBottom .4s ease-out both;
				animation-delay: 1s !important;
			}
		}

		&__options {
			position: relative;
			flex: 1;
			display: flex;
			justify-content: space-between;
			min-height: rem(160);
			max-height: rem(240);
			margin-top: auto;

			.draggable-item {
				.build-enter-active & {
					animation: slideInBottom .4s ease-out both;
					animation-delay: 1.2s;

					&:first-child {
						animation-delay: 1s;
					}
				}
				.build-leave-active & {
					animation: slideOutBottom .4s ease-out both;
					animation-delay: 0s !important;

					&.in-range {
						animation-delay: 1s !important;
					}
				}
			}
		}
			&__help {
				z-index: 2;
				bottom: auto;
				top: 0;
				padding: 1em 2em;

				&::before,
				&::after {
					content: '';
					position: absolute;
					top: 100%;
					border: solid transparent;
					border-width: 10px;
					border-top-color: white;
				}

				&::before {
					left: 2em;
					border-left-color: white;
				}
				&::after {
					left: auto;
					right: 2em;
					border-right-color: white;
				}
			}

	@include breakpoint($min-height: 800) {
		&__header {
			margin-block: auto;
		}
	}
}

.dropzone {
	position: relative;
	display: flex;
	flex: none;
	justify-content: center;
	align-items: center;
	@include size(184);
	margin: 0 auto;

	@include breakpoint($min-height: 620) {
		@include size(230);
	}

	.timer-shape {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
	}

	&__shape {
		width: rem(140);
		height: rem(185);

		path {
			fill-opacity: 0.16;
			stroke: #FFFFFF;
			stroke-width: 4;
			stroke-linecap: round;
			stroke-dasharray: 3, 10;

			.is-focused & {
				animation: dropzoneFocus .4s linear infinite;
			}
		}

		@include breakpoint($min-height: 700) {
			width: rem(194);
			height: rem(256);
		}
	}

	&__help {
		min-width: rem(140);
	}
}
</style>
