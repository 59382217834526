var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppScreen',{class:( _obj = {
		'levelscreen': true,
		'is-change-up': _vm.isLevelUp,
		'is-change-down': ! _vm.isLevelUp
	}, _obj[ 'is-level-' + ( _vm.level.index ) ] = true, _obj['show-recovery'] =  _vm.showRecover, _obj ),attrs:{"name":"levelChange","transition":{
		name: 'build',
		duration: 3000,
	}},on:{"toggle":_vm.toggleActive}},[_c('div',{staticClass:"levelscreen__header"},[_c('h2',[_vm._v(_vm._s(_vm.title))]),_c('p',{directives:[{name:"content",rawName:"v-content",value:(_vm.text),expression:"text"}],staticClass:"lead"}),_c('LevelBar',{attrs:{"old-level":_vm.oldLevel,"new-level":_vm.newLevel,"animate":_vm.isLevelUp ? 'up' : 'down'}}),(! _vm.isLevelUp)?_c('div',{staticClass:"button-group is-stacked"},[(_vm.offerRecover)?_c('AppButton',{attrs:{"name":"recover","location":_vm.level.name,"is-primary":""},on:{"click":function($event){return _vm.$emit( 'recover' )}}},[_vm._v(" "+_vm._s(_vm.$l10n( 'recover-level' ))+" ")]):_vm._e(),_c('AppButton',{attrs:{"name":"skip-recover","location":_vm.level.name},on:{"click":function($event){return _vm.$emit( 'done' )}}},[_vm._v(" "+_vm._s(_vm.$l10n( _vm.offerRecover ? 'skip' : 'next' ))+" ")])],1):_vm._e()],1),_c('div',{staticClass:"levelscreen__graphic",attrs:{"aria-hidden":"true"}},[_c('AppCharacter',{attrs:{"type":"answer","color":_vm.avatar.name,"animation":"reaction","clip":_vm.animation}})],1),(_vm.isLevelUp)?_c('div',{staticClass:"levelscreen__footer"},[(_vm.didRecover)?[_c('AppButton',{attrs:{"name":"continue","location":_vm.level.name,"is-primary":""},on:{"click":function($event){return _vm.$emit( 'done' )}}},[_vm._v(" "+_vm._s(_vm.$l10n( 'next' ))+" ")])]:[_c('AppButton',{attrs:{"name":"skip","location":_vm.level.name},on:{"click":function($event){return _vm.$emit( 'done' )}}},[_vm._v(" "+_vm._s(_vm.$l10n( 'skip-reward' ))+" ")]),_c('AppButton',{attrs:{"name":"continue","location":_vm.level.name,"is-primary":""},on:{"click":function($event){return _vm.$emit( 'claim' )}}},[_vm._v(" "+_vm._s(_vm.$l10n( 'next' ))+" ")])]],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }