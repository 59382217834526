var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"draggable-question"},[_c('div',{staticClass:"draggable-question__header subheading",domProps:{"innerHTML":_vm._s(_vm.content)}}),_c('div',{class:{
			'draggable-question__body': true,
			'is-double': _vm.options.length <= 2,
			'is-repeat': _vm.isRepeat,
		}},[_c('div',{ref:"dropzone",class:{
				'dropzone': true,
				'is-focused': _vm.optionInRange,
			},attrs:{"aria-hidden":"true"}},[_c('svg',{staticClass:"dropzone__shape",attrs:{"viewBox":"0 0 194 256"}},[_c('path',{attrs:{"d":_vm.dropzoneShape}})]),(_vm.useTimers)?_c('TimerShape',{attrs:{"width":60,"height":60,"type":_vm.isRepeat ? 'cloud' : 'circle',"duration":_vm.timer,"start":_vm.isActive,"delay":2,"paused":_vm.activeModals.length > 0 || _vm.isMobileLandscape,"animate":_vm.activeOption !== false},on:{"expire":_vm.skipAnswer}}):_vm._e()],1),_c('div',{staticClass:"draggable-question__options"},[_c('Transition',[(_vm.showTooltip && ! _vm.hasSelected)?_c('AppTooltip',{staticClass:"draggable-question__help"},[_c('span',{directives:[{name:"content",rawName:"v-content",value:(_vm.$l10n( 'drag-help' )),expression:"$l10n( 'drag-help' )"}]})]):_vm._e()],1),_vm._l((_vm.options),function(option,id){return _c('DraggableQuestionOption',_vm._b({key:id,attrs:{"color":_vm.colors[ id ],"target":_vm.$refs.dropzone,"enabled":_vm.isActive && ( _vm.activeOption === id || _vm.activeOption === false ),"margin":0,"idle-clip":_vm.idleClips[ id ]},on:{"pickup":_vm.hideTooltip,"update":_vm.updateActiveOption,"drag":_vm.updateOptionInRange,"drop":_vm.validateOptionDrop}},'DraggableQuestionOption',option,false))})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }