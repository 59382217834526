import Vue from 'vue';
import Vuex from 'vuex';

Vue.use( Vuex );

export const avatars = [
	{
		name: 'capacitor',
		label: 'capacitor-name',
		color: 'magenta',
		description: 'capacitor-description',
	},
	{
		name: 'ceramic',
		label: 'ceramic-name',
		color: 'blue',
		description: 'ceramic-description',
	},
	{
		name: 'block',
		label: 'block-name',
		color: 'yellow',
		description: 'block-description',
	},
];

export default new Vuex.Store( {
	state: {
		avatar: avatars[ 0 ],

		answers: [],
		score: 0, // used to calculate levels; correctAnswer - incorrectAnswers + recoveryAnswers
		trueScore: 0, // used to determine result; correctAnswer total
		rewards: [],

		currentScreen: '',
		currentTab: '',
		activeModals: [],

		baseURL: '/',
		useAudio: false,
		useMusic: true,
		useSounds: true,
		useTimers: true,
		showDragHelper: true,

		isTouchDevice: false,
		isMobileLandscape: false,

		session: '',
	},
	getters: {
		getAnswer( state ) {
			return ( question ) => {
				const entry = state.answers.find( e => e.question === question );

				return entry ? entry.answer : null;
			};
		},
	},
	mutations: {
		setAvatar( state, avatar ) {
			state.avatar = avatar;
		},

		saveAnswer( state, entry ) {
			state.answers = [ ...state.answers, entry ];
		},

		incrementScore( state ) {
			state.score++;
		},
		decrementScore( state ) {
			state.score--;
		},
		changeScore( state, result ) {
			const change = result ? 1 : -1;
			state.score = Math.max( 0, state.score + change );
			state.trueScore += result ? 1 : 0;
		},

		addReward( state, reward ) {
			if ( ! state.rewards.includes( reward ) ) {
				state.rewards.push( reward );
			}
		},

		goToScreen( state, name ) {
			state.currentScreen = name;
		},
		goToTab( state, name ) {
			state.currentTab = name;
		},

		showModal( state, name ) {
			if ( ! state.activeModals.includes( name ) ) {
				state.activeModals.push( name );
			}
		},
		hideModal( state, name ) {
			const activeModals = [ ...state.activeModals ];
			const index = activeModals.indexOf( name );
			if ( index >= 0 ) {
				activeModals.splice( index, 1 );
				state.activeModals = activeModals;
			}
		},

		enableAudio( state ) {
			state.useAudio = true;
		},
		disableAudio( state ) {
			state.useAudio = false;
		},
		toggleAudio( state ) {
			state.useAudio = ! state.useAudio;
		},

		toggleMusic( state, toggle ) {
			state.useMusic = toggle;
		},
		toggleSounds( state, toggle ) {
			state.useSounds = toggle;
		},
		toggleTimers( state, toggle ) {
			state.useTimers = toggle;
		},
		toggleDragHelper( state, toggle ) {
			state.showDragHelper = toggle;
		},

		setMobileLandscape( state, value ) {
			state.isMobileLandscape = value;
		},

		setSession( state, value ) {
			state.session = value;
		},

		reset( state ) {
			state.avatar = avatars[ 0 ];
			state.answers = [];
			state.score = 0;
			state.trueScore = 0;
			state.activeModals = [];
			state.currentScreen = '';
		},
	},
} );
