<template>
	<AppScreen
		v-if="text"
		name="recoveryNotice"
		class="noticescreen"
		:transition="{
			name: 'build',
			duration: 2000,
		}"
	>
		<div class="noticescreen__body">
			<h2>{{ $l10n( 'recover-title' ) }}</h2>
			<p v-content="text" />

			<AppButton
				name="continue"
				:location="name"
				is-primary
				@click="$emit( 'done' )"
			>
				{{ $l10n( 'continue' ) }}
			</AppButton>
		</div>

		<div
			class="noticescreen__graphic"
			aria-hidden="true"
		>
			<AppCharacter
				:type="avatar.name"
				color="magenta"
			/>
		</div>
	</AppScreen>
</template>

<script>
import { mapState } from 'vuex';

export default {
	props: {
		name: {
			type: String,
			required: true,
		},
		text: {
			type: String,
			required: true,
		},
	},
	computed: mapState( [ 'avatar' ] ),
};
</script>

<style lang="scss">
.noticescreen {
	justify-content: flex-end;
	text-align: center;

	&__body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: auto;

		.build-enter-active & {
			animation: slideInTop .4s ease-out both;
		}
		.build-leave-active & {
			animation: slideOutTop .4s ease-out both;
		}
	}

	&__graphic {
		position: relative;
		flex: 1;
		margin-top: auto;
		max-height: 240px;

		.character {
			position: absolute;
			top: 0;
			left: 50%;
			width: rem(240);
			transform: translateX(-50%);
		}

		.build-enter-active & {
			animation: slideInBottom .4s ease-out both;
			animation-delay: .4s;
		}
		.build-leave-active & {
			animation: slideOutBottom .4s ease-out both;
		}
	}
}
</style>
