<template>
	<Transition
		v-bind="transition"
		:css="! transitionHooks"
		v-on="transitionHooks"
	>
		<div
			v-if="isOpen"
			:id="id"
			role="main"
			:class="{
				'screen': true,
				'is-open': isOpen,
			}"
			tabindex="-1"
		>
			<slot />
		</div>
	</Transition>
</template>

<script>
import { mapState } from 'vuex';

export default {
	inheritAttrs: false,
	emits: [ 'show', 'hide', 'toggle' ],
	props: {
		name: {
			type: String,
			required: true,
		},
		id: {
			type: String,
			default: '',
		},
		transition: {
			type: Object,
			default: () => ( {
				name: 'fade',
			} ),
		},
		transitionHooks: {
			type: Object,
			default: null,
		},
	},
	computed: mapState( {
		isOpen( state ) {
			return state.currentScreen === this.name;
		},
	} ),
	watch: {
		isOpen( isOpen ) {
			if ( isOpen ) {
				this.$emit( 'show' );
				this.$nextTick( () => this.$el.focus() );
			} else {
				this.$el.blur();
				this.$emit( 'hide' );
			}

			this.$emit( 'toggle', isOpen );
		},
	},
	provide() {
		return {
			parentView: this.name,
		};
	},
};
</script>

<style lang="scss">
.screen {
	position: fixed;
	z-index: 1;
	inset: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	padding: rem(70) rem(20) rem(30);

	&.build-enter-active,
	&.build-leave-active {
		overflow: hidden;
		transition: .4s ease-out;
		transition-property: opacity, visibility;
	}
	&.build-leave-active {
		transition-delay: .4s;
	}
	&.build-enter,
	&.build-leave-to {
		opacity: 0;
		visibility: hidden;
	}

	@include breakpoint($max-height: 550) {
		padding: rem(60) rem(20) rem(20);
	}
}

[class*="__header"],
[class*="__body"],
[class*="__graphic"],
[class*="__footer"] {
	width: 100%;
	max-width: rem(600);
	margin-inline: auto;
}
</style>
