<template>
	<AppButton
		:name="useAudio ? 'mute' : 'unmute'"
		location="main"
		:class="{
			'sound-toggle': true,
			'is-muted': ! useAudio,
		}"
		icon="sound"
		is-plain
		@click="toggleAudio"
	>
		{{ $l10n( useAudio ? 'mute' : 'unmute' ) }}
	</AppButton>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
	computed: {
		...mapState( [ 'useAudio' ] ),
	},
	methods: mapMutations( [
		'toggleAudio',
	] ),
};
</script>

<style lang="scss">
@keyframes soundBar {
	from {
		transform: scaleY(.5);
	}
	to {
		transform: scaleY(1.2);
	}
}

.sound-toggle {
	.x {
		visibility: hidden;
	}
	.bar1,
	.bar2,
	.bar3 {
		animation: soundBar .6s ease-in-out alternate infinite;
		transform-origin: center;
	}

	.bar1 {
		animation-delay: .2s;
	}
	.bar2 {
		animation-delay: .4s;
	}

	&.is-muted {
		.x {
			visibility: inherit;
		}
		.bar1,
		.bar2,
		.bar3 {
			visibility: hidden;
		}
	}
}
</style>
