<template>
	<div class="button-group">
		<AppButton
			v-for="site in sites"
			:key="site.id"
			class="st-custom-button"
			:data-network="site.id"
			:data-url="url"
		>
			{{ site.label }}
		</AppButton>
	</div>
</template>

<script>
export default {
	props: {
		url: {
			type: String,
			required: true,
		},
		sites: {
			type: Array,
			required: true,
		},
	},
};
</script>
