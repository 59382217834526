import { Howl } from 'howler';
import store from '../store';

function noop() {}

function convertTime( string ) {
	const [ minutes, seconds, milliseconds ] = string.split( /\D+/ ).map( v => parseInt( v ) );

	return ( ( ( minutes * 60 ) + seconds ) * 1000 ) + milliseconds;
}

class Soundboard {
	constructor( source = '', clips = [] ) {
		const sprite = {};

		for ( const clip of clips ) {
			let [ name, start, end ] = clip;

			start = convertTime( start );
			const length = convertTime( end ) - start;

			sprite[ name ] = [ start, length ];
		}

		this.api = new Howl( {
			src: [ source ],
			sprite,
		} );

		this._clips = {};
	}

	start( clip, noLoop ) {
		const id = this.play( clip );
		this.api.loop( ! noLoop, id );

		if ( this._clips[ clip ] ) {
			this._clips[ clip ].stop();
		}

		const api = this._clips[ clip ] = {
			play: () => this.api.play( id ),
			pause: () => this.api.pause( id ),
			stop: () => this.api.stop( id ),
			volume: level => this.api.volume( level, id ),
			mute: ( muted = true ) => this.api.mute( muted, id ),
			fade: ( start, end, duration ) => this.api.fade( start, end, duration, id ),
		};

		return api;
	}

	stop() {
		this.api.stop();

		return this;
	}

	mute( muted = true ) {
		this.api.mute( muted );

		return this;
	}

	get( clip ) {
		return this._clips[ clip ] || {
			play: noop,
			pause: noop,
			stop: noop,
			volume: noop,
			fade: noop,
		};
	}

	play( clip ) {
		return this.api.play( clip );
	}
}

export default {
	install( Vue, source, clips ) {
		const soundboard = new Soundboard( source, clips );
		Vue.prototype.$sounds = soundboard;

		// Mute if audio or sounds is false
		soundboard.api.mute( ! store.state.useAudio || ! store.state.useSounds );

		store.watch(
			state => state.useAudio,
			// Mute if either audio or sounds is false, otherwise unmute
			status => soundboard.mute( ! status || ! store.state.useSounds ),
		);

		store.watch(
			state => state.useSounds,
			// Mute if either audio or sounds is false, otherwise unmute
			status => soundboard.mute( ! status || ! store.state.useAudio ),
		);
	},
};
