<template>
	<AppScreen
		name="home"
		class="homescreen"
		:transition="{ name: 'fade-in' }"
	>
		<div class="homescreen__header">
			<h1 class="homescreen__title">
				{{ title }}
			</h1>
			<p
				class="homescreen__tagline"
				v-html="tagline"
			/>
			<AppButton
				name="start"
				location="home"
				is-primary
				@click="$emit( 'next' )"
			>
				{{ buttonText || $l10n( 'start' ) }}
			</AppButton>
		</div>
		<AppImage
			:srcset="[
				require('@/assets/img/homescreen-capacitor.webp'),
				require('@/assets/img/homescreen-capacitor.png'),
			]"
			width="458"
			height="376"
			class="homescreen__decor"
		/>
	</AppScreen>
</template>

<script>
import { mapState } from 'vuex';

export default {
	emits: [ 'next' ],
	props: {
		title: {
			type: String,
			default: '',
		},
		tagline: {
			type: String,
			default: '',
		},
		buttonText: {
			type: String,
			default: '',
		},
		privacyLink: {
			type: String,
			default: '',
		},
		tosLink: {
			type: String,
			default: '',
		},
	},
	computed: mapState( [ 'session' ] ),
};
</script>

<style lang="scss">
.homescreen {
	justify-content: flex-start;
	align-items: center;
	text-align: center;
	font-size: rem(24);
	padding: rem(30) rem(30) 0;

	&__header {
		position: relative;
		z-index: 4;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: auto;

		.button__inner {
			padding-inline: rem(30);
		}
		.sound-toggle {
			margin-top: rem(10);
		}
	}
		&__title {
			font-size: rem(40);
			line-height: 1;
			margin: 0 0 rem(10);

			[lang="zh"] & {
				text-shadow: .05em .05em 0 $color-white;
			}
		}
		&__tagline {
			font-size: rem(20);
			max-width: rem(300);

			strong {
				font-weight: $font-weight-black;
			}
		}

	@include breakpoint($min-height: 700) {
		font-size: rem(28);

		&__title {
			font-size: rem(48);

			[lang="zh"] & {
				font-size: rem(80);
			}
		}
		&__tagline {
			font-size: rem(24);
		}
	}

	@include breakpoint($max-width: 640) {
		&__title {
			font-size: rem(32);

			[lang="zh"] & {
				font-size: rem(40);
			}
		}
	}

	@include breakpoint($min-width: 700) {
		&__title {
			[lang="zh"] & {
				font-size: rem(72);
			}
		}
	}

	&__decor {
		z-index: 3;
		margin-bottom: rem(-20);
		width: rvh(340);
		height: auto;
		animation: slideInBottom .4s .6s ease-out both;

		@include breakpoint($min-height: 900) {
			width: rem(458);
		}
	}
}
</style>
