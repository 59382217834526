<template>
	<AppScreen
		name="reward"
		class="rewardscreen"
		:transition="{
			name: 'build',
			duration: 2000,
		}"
		aria-live="polite"
	>
		<div class="rewardscreen__header">
			<h2>{{ $l10n( 'choose-reward' ) }}</h2>
		</div>
		<div class="rewardscreen__body">
			<RewardOptions
				:enabled="enabled"
				:boxes="[ $l10n( 'box1' ), $l10n( 'box2' ) ]"
				@select="selectReward"
			/>
		</div>
		<AppModal
			name="reward"
			class="reward"
			is-mini
			no-close
		>
			<div
				v-if="theReward"
				class="reward__preview"
			>
				<div class="reward__frame">
					<img
						v-bind="theReward.thumbnail"
						class="reward__image-preview"
					/>
					<img
						v-bind="theReward.download"
						class="reward__image-full"
						:alt="theReward.name"
						@contextmenu="logDownload( theReward.name )"
					/>
				</div>
			</div>
			<p>{{ instructions }}</p>
		</AppModal>
		<Transition type="build">
			<div
				v-if="reward >= 0"
				class="rewardscreen__footer"
			>
				<AppButton
					name="continue"
					:location="level.name"
					is-primary
					@click="finish"
				>
					{{ $l10n( 'next' ) }}
				</AppButton>
			</div>
		</Transition>
	</AppScreen>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import RewardOptions from './RewardOptions.vue';

export default {
	components: {
		RewardOptions,
	},
	props: {
		level: {
			type: Number,
			required: true,
		},
		options: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			enabled: true,
			reward: -1,
			theReward: null,
		};
	},
	computed: mapState( {
		availableRewards( state ) {
			return this.options.filter( option => ! state.rewards.includes( option ) );
		},

		instructions( state ) {
			return state.isTouchDevice ? this.$l10n( 'download-instructions-tap' ) : this.$l10n( 'download-instructions-mouse' );
		},
	} ),
	watch: {
		level() {
			this.enabled = true;
			this.reward = -1;
			this.hideModal( 'reward' );
		},
	},
	methods: {
		...mapMutations( [ 'addReward' ] ),

		selectReward( index ) {
			this.enabled = false;
			this.reward = index;

			this.theReward = this.availableRewards[ Math.floor( Math.random() * this.availableRewards.length ) ];

			setTimeout( () => this.showModal( 'reward' ), 250 );
		},

		finish() {
			this.hideModal( 'reward' );
			this.addReward( this.theReward );
			setTimeout( () => this.$emit( 'done' ), 1000 );
		},

		logDownload( name ) {
			this.$log.event( {
				event_type: 'download',
				target: name,
				location: this.parentView,
			} );
		},
	},
};
</script>

<style lang="scss">
.rewardscreen {
	justify-content: space-between;
	text-align: center;

	&__header {
		margin-bottom: rem(20);

		.build-enter-active & {
			animation: slideInLeft .4s ease-out both;
		}
		.build-leave-active & {
			animation: slideOutRight .4s ease-out both;
		}
	}

	&__body {
		flex: 1;
		width: 100%;
		margin-top: auto;
		max-height: 420px;

		.build-leave-active & {
			animation: slideOutBottom .4s ease-out both;
		}
	}

	&__footer {
		position: absolute;
		z-index: 3;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		justify-content: flex-end;
		padding: 0 rem(20) rem(20);

		> :not(:first-child) {
			margin-left: 1em;
		}

		&.build-enter-active {
			animation: slideInBottom .4s ease-out both;
		}
		.build-leave-active & {
			animation: slideOutBottom .4s ease-out both;
		}
	}
}

.reward {
	z-index: 2 !important;

	.modal__frame {
		max-width: rem(320);
	}
		.modal__body {
			padding: rem(20) rem(20) rem(30);
		}

	&__preview {
		position: relative;
		overflow: hidden;
		@include with-aspect;

		+ * {
			margin-top: 1em;
		}
	}
		&__frame {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			width: rem(206);
			height: rem(344);
			margin: 0 auto;
			background: $color-dark;
			border: 14px solid transparent;
			border-radius: 20px;
			transform: translateY(4%) rotate(11deg);

			img {
				position: absolute;
				inset: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 10px;
				margin-bottom: rem(30);
				background: grey;
			}
		}
			&__image-preview {
				pointer-events: none;
			}
			&__image-full {
				z-index: 1;
			}
}
</style>
