import { fetch } from 'whatwg-fetch';
import { v4 as uuid } from 'uuid';

function saveData( key, value, ttl ) {
	localStorage.setItem( key, JSON.stringify( {
		value,
		expires: Date.now() + ttl,
	} ) );
}

function loadData( key ) {
	const json = localStorage.getItem( key );

	if ( ! json ) {
		return null;
	}

	const item = JSON.parse( json );
	if ( Date.now() > item.expires ) {
		localStorage.removeItem( key );
		return null;
	}

	return item.value;
}

class Logger {
	constructor( apiURL = '' ) {
		this.apiURL = apiURL;
	}

	async init( surveyName ) {
		const [ url, version = '' ] = window.location.href.split( '#' );

		let userId = loadData( 'userId' );
		if ( ! userId ) {
			userId = uuid();
			saveData( 'userId', userId, 1000 * 60 * 60 * 24 * 28 );
		}

		this.session = await this.send( 'sessions', {
			survey_name: surveyName,
			url,
			version,
			language: document.documentElement.lang,
			user_id: userId,
		} );

		return this.session;
	}

	async send( table, row ) {
		if ( ! this.apiURL ) {
			return Promise.resolve( {} );
		}

		const payload = { table, row };

		try {
			const response = await fetch( this.apiURL, {
				method: 'POST',
				headers: {
					'content-type': 'application/json',
				},
				mode: 'cors',
				body: JSON.stringify( payload ),
			} );

			const responseText = await response.text();

			// Pixel tracker
			try {
				if ( table === 'sessions' ) {
					row.id = responseText;
                    row.user_id = loadData( 'userId' );
				}
				const params = encrypt( new URLSearchParams( row ).toString() );
				await fetch( `/pixel?tbl=${ table }&${ params }` );
			} catch ( exception ) {
				console.error( exception );
			}

			return responseText;
		} catch ( exception ) {
			console.error( exception );
		}
	}

	sendForSession( table, data ) {
		return this.send( table, {
			session_id: this.session,
			...data,
		} );
	}

	answer( {
		question_type,
		question_name,
		answer_text,
		duration_in_seconds = 0,
		result = '',
	} ) {
		if ( typeof result === 'boolean' ) {
			result = result ? 'correct' : 'incorrect';
		}

		return this.sendForSession( 'answers', {
			question_type,
			question_name,
			answer_text,
			duration_in_seconds,
			result,
		} );
	}

	demographic( { question_name, answers } ) {
		return this.sendForSession( 'demographics', {
			question_name,
			answers,
		} );
	}

	event( { event_type, target, location = '', question_name = '' } ) {
		return this.sendForSession( 'events', {
			event_type,
			target,
			location,
			question_name,
		} );
	}

	correction( { question_name, source_url, comments } ) {
		return this.sendForSession( 'corrections', {
			question_name,
			source_url,
			comments,
		} );
	}
}

/* Encrypt <message> with a simple mixed alphabet cipher
 */
function encrypt( message ) {
	const source_characters = 'abcdefghijklmnopqrstuvwxyz0123456789'.split( '' );
	const target_characters = 'g1xcou5bnklir28djv3sqwyhfm409z6tap7e'.split( '' );
	const zip = ( a, b ) => a.map( ( k, i ) => [ k, b[ i ] ] );
	const cipher = Object.fromEntries( zip( source_characters, target_characters ) );
	return message.replace( /[a-z0-9]/g, c => cipher[ c ] );
}

export default {
	install( Vue, apiURL, surveyName, store ) {
		const logger = new Logger( apiURL );

		logger.start = function() {
			logger.init( surveyName ).then( ( session ) => {
				store.commit( 'setSession', session );
			} );
		};

		Vue.prototype.$log = logger;
	},
};
