<template>
	<AppModal
		name="share"
		:class="[ 'sharescreen', 'is-level-' + level ]"
	>
		<h2>{{ $l10n( 'share-level' ) }}</h2>

		<div class="sharescreen__poster">
			<StarGraphic
				:number="level"
				class="sharescreen__star"
			/>
		</div>

		<div class="sharescreen__info">
			<p v-content="text" />

			<ShareButtonGroup
				:sites="sites"
				:url="url"
			/>
		</div>
	</AppModal>
</template>

<script>
import StarGraphic from './StarGraphic';
import ShareButtonGroup from './ShareButtonGroup';

export default {
	components: {
		StarGraphic,
		ShareButtonGroup,
	},
	props: {
		level: {
			type: Number,
			required: true,
		},
		text: {
			type: String,
			required: true,
		},
		sites: {
			type: Array,
			required: true,
		},
	},
	computed: {
		url() {
			let url = window.location.origin + this.$store.state.baseURL;
			if ( this.level ) {
				url += `l${ this.level }.html`;
			}

			return url;
		},
	},
};
</script>

<style lang="scss">
.sharescreen {
	text-align: center;

	&__poster {
		overflow: hidden;
	}
		&__star {
			width: 200px;
			margin: 0 auto rem(40);

			.star__label {
				font-size: rem(100);
			}
		}
}
</style>
