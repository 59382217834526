<template>
	<div>
		<p><strong>{{ $l10n( 'current-rank' ) }}</strong> {{ $l10n( 'level', $l10n( getLevel() ) ) }}</p>
		<LevelBar
			:level="getLevel()"
			:max="getMaxLevel()"
			is-static
		/>

		<div v-if="rewards.length > 0">
			<h2>{{ $l10n( 'saved-rewards' ) }}</h2>
			<p>{{ instructions }}</p>
			<ul class="reward-list">
				<li
					v-for="(reward, index) in rewards"
					:key="index"
				>
					<div class="reward-preview">
						<img
							v-bind="reward.download"
							:alt="reward.name"
							@contextmenu="logDownload( reward.name )"
						/>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import LevelBar from './LevelBar.vue';

export default {
	components: {
		LevelBar,
	},
	inject: [ 'getLevel', 'getMaxLevel' ],
	computed: mapState( {
		rewards: state => state.rewards,

		instructions( state ) {
			return state.isTouchDevice ? this.$l10n( 'download-instructions-tap' ) : this.$l10n( 'download-instructions-mouse' );
		},
	} ),
	methods: {
		logDownload( name ) {
			this.$log.event( {
				event_type: 'download',
				target: name,
				location: this.parentView,
			} );
		},
	},
};
</script>

<style lang="scss">
.tab {
	.levelbar {
		margin-top: 60px;
		margin-bottom: 80px;

		&__meter {
			background: rgba($color-light, .5);
		}
	}
}

.reward-list {
	display: grid;
	grid-gap: rem(20);
	grid-template-columns: 1fr 1fr;
	text-align: center;
}
	.reward-preview {
		position: relative;
		display: block;
		border-radius: rem(10);
		@include with-aspect;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: rem(10);
		}

		.icon-wrapper {
			position: absolute;
			bottom: .5em;
			right: .5em;
			display: grid;
			place-items: center;
			background: rgba(#000, .16);
			@include size(60);
			color: #fff;
			border: 2px solid;
			border-radius: 50%;
		}
			.icon {
				@include size(26);
				fill: currentColor;
			}
	}
</style>
