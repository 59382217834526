<template>
	<div
		class="carousel"
		aria-live="polite"
	>
		<div
			v-if="withPagination"
			class="carousel__pagination"
		>
			<button
				class="carousel__pagination-button prev"
				:disabled="current <= 0"
				@click="gotoSlide( current - 1 )"
			>
				{{ $l10n( 'prev' ) }}
				<AppGraphic type="prev" />
			</button>
			<label
				v-for="(slide, index) in $slots.default"
				:key="index"
				class="carousel__pagination-dot"
			>
				<input
					type="radio"
					:name="`v${ _uid }`"
					:value="index"
					:checked="index === current"
					@input="gotoSlide( index )"
				/>
				<ScreenReaderText>Slide {{ index + 1 }}</ScreenReaderText>
			</label>
			<button
				class="carousel__pagination-button next"
				:disabled="current >= $slots.default.length - 1"
				@click="gotoSlide( current + 1 )"
			>
				{{ $l10n( 'next' ) }}
				<AppGraphic type="next" />
			</button>
		</div>
		<div
			ref="scroller"
			class="carousel__slides-wrap"
			:style="{
				'--count': $slots.default.length,
			}"
		>
			<ul
				ref="wrapper"
				class="carousel__slides"
			>
				<Slide
					v-for="(slide, index) in $slots.default"
					:key="index"
					:content="slide"
					:is-current="index === current"
					:class="`carousel__slide--${ index + 1 }`"
					:aria-hidden="index !== current"
					@enter="onEnter( index )"
					@select="onSelect( index )"
				/>
			</ul>
		</div>
	</div>
</template>

<script>
import BaseCarouselSlide from './BaseCarouselSlide.vue';

const tabletQuery = window.matchMedia( '(min-width:48em)' );

const Slide = {
	props: {
		content: {
			type: Object,
			required: true,
		},
		isCurrent: Boolean,
	},
	render( createElement ) {
		return createElement(
			BaseCarouselSlide,
			{
				on: this.$listeners,
				class: { 'is-current-slide': this.isCurrent },
			},
			[ this.content ],
		);
	},
};

export default {
	emits: [ 'update' ],
	components: {
		Slide,
	},
	props: {
		position: {
			type: Number,
			default: 0,
		},
		withPagination: Boolean,
	},
	data() {
		return {
			current: 0,
		};
	},
	watch: {
		current( index ) {
			this.$emit( 'update', index );
		},
	},
	mounted() {
		this.gotoSlide( this.position );
	},
	methods: {
		gotoSlide( index ) {
			const { scroller, wrapper } = this.$refs;

			const slide = wrapper.children[ index ];
			if ( slide ) {
				scroller.scrollLeft = slide.offsetLeft - ( scroller.offsetWidth / 2 );
			}
		},
		onEnter( index ) {
			if ( ! tabletQuery.matches ) {
				this.current = index;
			}
		},
		onSelect( index ) {
			if ( tabletQuery.matches ) {
				this.current = index;
			}
		},
	},
};
</script>

<style lang="scss">
.carousel {
	margin-block: rem(16);

	&__pagination {
		display: flex;
		justify-content: center;
		align-items: center;

		&-button {
			font: 0/0 a;
			@include size(40);
			background: $color-white;
			border-radius: 50%;
			box-shadow: 0px 6px 0px rgba(#000, .2);
			margin: 0 14px;

			.icon {
				display: block;
				@include size(22);
				margin: auto;
			}

			&:disabled {
				opacity: .2;
			}
		}

		&-dot {
			margin: 0 6px;

			input {
				display: block;
				appearance: none;
				@include size(16);
				border-radius: 50%;
				background: rgba($color-white, .5);
				box-shadow: 0 0 0 0 $color-white;
				transition: background .2s, box-shadow .2s;

				&:checked {
					background: $color-pink;
					box-shadow: 0 0 0 4px $color-white;
				}
			}
		}
	}

	&__slides-wrap {
		position: relative;
		width: 100vw;
		overflow: auto hidden;
		scroll-snap-type: x mandatory;
		scroll-behavior: smooth;

		&::-webkit-scrollbar {
			height: 0;
		}
	}

	&__slides {
		display: grid;
		grid-gap: rem(20);
		grid-template-columns: rem(40) repeat(var(--count, 1), calc(100vw - #{rem(80)})) rem(40);

		&::before,
		&::after {
			content: '';
		}
	}

	@include breakpoint($min-width: 768) {
		&__pagination {
			display: none;
		}

		&__slides-wrap {
			width: auto;
			max-width: 100vw;
			overflow: hidden;
		}
		&__slides {
			grid-template-columns: 0 repeat(var(--count, 1), 1fr) 0;
		}
	}
}
</style>
