var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:( _obj = {
		'levelbar': true,
		'is-maxed': _vm.max && _vm.level === _vm.max,
		'static': _vm.isStatic,
		'animate': _vm.animate
	}, _obj[ ("animate--" + _vm.animate) ] = _vm.animate, _obj )},[_c('div',{staticClass:"levelbar__meter"},[_c('div',{staticClass:"levelbar__meter__fill"}),_c('StarGraphic',{staticClass:"levelbar__star levelbar__value"},[(_vm.level !== null)?_c('span',{staticClass:"star__label"},[_vm._v(" "+_vm._s(_vm.level)+" ")]):[_c('span',{staticClass:"star__label start-label"},[_vm._v(" "+_vm._s(_vm.oldLevel)+" ")]),_c('span',{staticClass:"star__label end-label"},[_vm._v(" "+_vm._s(_vm.newLevel)+" ")])]],2),_c('StarGraphic',{staticClass:"levelbar__star levelbar__placeholder"},[(_vm.level !== null)?_c('span',{staticClass:"star__label"},[_vm._v(" "+_vm._s(_vm.level + 1)+" ")]):_c('span',{staticClass:"star__label"},[_vm._v(" "+_vm._s(_vm.newLevel)+" ")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }