<template>
	<AppModal
		class="correction"
		:name="id"
	>
		<Transition>
			<div
				v-if="submitted"
				class="correction-result"
			>
				<p class="heading">
					{{ $l10n( 'correction-thanks' ) }}
				</p>

				<div
					class="correction__graphic"
					aria-hidden="true"
				>
					<AppCharacter
						type="capacitor"
						color="yellow"
						animation="interaction"
						clip="picked"
					/>
				</div>
			</div>
			<form
				v-else
				class="correction-form"
				@submit.prevent="submit"
			>
				<div
					v-content="explanation"
					class="correction-form__preface"
				/>

				<label
					class="correction-form__label"
					:for="`${ id }-source`"
				>
					{{ $l10n( 'correction-source' ) }}
				</label>
				<input
					:id="`${ id }-source`"
					ref="correctionSource"
					v-model="source"
					class="correction-form__input"
					type="text"
					required
				/>

				<label
					class="correction-form__label"
					:for="`${ id }-comments`"
				>{{ $l10n( 'correction-comments' ) }}</label>
				<textarea
					:id="`${ id }-comments`"
					ref="correctionComments"
					v-model="comments"
					class="correction-form__input"
					rows="5"
					maxlength="500"
					required
				/>

				<div class="correction-form__actions">
					<AppButton
						type="reset"
						@click="hideModal( id )"
					>
						{{ $l10n( 'cancel' ) }}
					</AppButton>
					<AppButton
						is-primary
						color="green"
						type="submit"
					>
						{{ $l10n( 'submit' ) }}
					</AppButton>
				</div>
			</form>
		</Transition>
	</AppModal>
</template>

<script>
import { mapState } from 'vuex';

export default {
	emits: [ 'submit' ],
	props: {
		id: {
			type: String,
			required: true,
		},
		explanation: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			source: null,
			comments: null,
			submitted: false,
		};
	},
	computed: mapState( [ 'avatar' ] ),
	methods: {
		submit() {
			this.submitted = true;
			this.$emit( 'submit', {
				source: this.source,
				comments: this.comments,
			} );
		},
	},
};
</script>

<style lang="scss">
.correction {
	.modal__body {
		flex: 1;
		display: flex;
		flex-direction: column;
	}
}

.correction-form {
	&__preface {
		text-align: center;
		margin-bottom: 2em;
	}

	&__label,
	&__input {
		font-size: rem(14);
		font-weight: $font-weight-medium;
	}
	&__label {
		margin-bottom: .5em;
	}
	&__input {
		padding: 1em;
		border: 1px solid $color-light;
		border-radius: .5em;
		margin-bottom: 1em;
	}

	&__actions {
		display: flex;
		justify-content: space-between;
	}
}

.correction-result {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;

	.heading {
		margin: auto 0 0;
	}
}
	.correction__graphic {
		flex: 1;
		margin-top: auto;
		max-height: 260px;
		position: relative;

		.character {
			position: absolute;
			top: 40px;
			left: 50%;
			width: rem(240);
			transform: translateX(-50%);
		}

		@include breakpoint($min-width: 400) {
			max-height: 300px;

			.character {
				width: rem(270);
			}
		}
	}
</style>
