<template>
	<img
		v-if="src"
		class="image"
		:src="src"
		:width="width"
		:height="height"
		:alt="alt"
	/>
	<picture
		v-else
		class="image"
	>
		<source
			v-for="source in sources"
			:key="source.url"
			:srcset="source.url"
			:type="source.type"
			:width="width"
			:height="height"
			:alt="alt"
		/>
		<img
			:src="srcset[0]"
			:width="width"
			:height="height"
			:alt="alt"
		/>
	</picture>
</template>

<script>
export default {
	props: {
		src: {
			type: String,
			default: null,
		},
		srcset: {
			type: Array,
			default: () => [],
		},
		width: {
			type: [ Number, String ],
			default: null,
		},
		height: {
			type: [ Number, String ],
			default: null,
		},
		alt: {
			type: String,
			default: '',
		},
	},
	computed: {
		sources() {
			return this.srcset.map( ( src ) => {
				let type = src.split( '.' ).pop().trim();
				if ( type === 'jpg' ) {
					type = 'jpeg';
				}

				return {
					url: src,
					type: `image/${ type }`,
				};
			} );
		},
	},
};
</script>
