<template>
	<div v-show="isMobileLandscape" class="app-landscape-notice">
		<div class="app-landscape-notice__body">
			<div class="app-landscape-notice__icon">
				<AppGraphic type="deviceRotate" />
			</div>
			<h2>{{ $l10n( 'rotate-device' ) }}</h2>
			<p>{{ $l10n( 'rotate-device-detail' ) }}</p>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
const FORM_ELEMENTS = [ 'input', 'textarea', 'select' ];

const landscapeQuery = window.matchMedia( '(orientation: landscape) and (max-height:500px)' );
function onLandscapeQueryChange( callback ) {
	if ( landscapeQuery.addEventListener ) {
		landscapeQuery.addEventListener( 'change', callback );
	} else {
		// for safari
		landscapeQuery.addListener( callback );
	}
}

export default {
	computed: mapState( [ 'isMobileLandscape' ] ),
	mounted() {
		const checkMobileLandscape = ( query ) => {
			this.setMobileLandscape( query.matches && ( window.orientation % 180 ) && ! FORM_ELEMENTS.includes( document.activeElement.tagName ) );
		};

		onLandscapeQueryChange( checkMobileLandscape );
		checkMobileLandscape( landscapeQuery );
	},
	methods: {
		...mapMutations( [ 'setMobileLandscape' ] ),
	},
};
</script>

<style lang="scss">
.app-landscape-notice {
	position: fixed;
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	z-index: 20;
	@include cover;
	background: $backdrop-gradient;
	padding: 60px 30px 30px;
	animation: fade .4s both;

	&__body {
		max-width: 50%;
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 1.8rem;
		text-align: center;

		h2 {
			font-size: 2.1rem;
			margin: 0;
		}
	}
		&__icon {
			margin: 0 0 20px;
			padding: 21px;
			background: $color-white;
			border: 2px solid;
			border-radius: 50%;
			box-shadow: 2px 2px 0;

			.icon {
				display: block;
			}
		}
}
</style>
