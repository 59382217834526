<template>
	<AppModal
		name="menu"
		class="menu"
	>
		<AppTabSet
			:tabs="[
				{
					name: 'rewards',
					title: $l10n( 'rewards' ),
					component: TheRewardsTab,
				},
				{
					name: 'settings',
					title: $l10n( 'settings' ),
					component: TheSettingsTab,
				},
				{
					name: 'about',
					title: $l10n( 'about' ),
					component: TheAboutTab,
					props: {
						about,
						privacyPolicy,
						termsOfUse,
					},
				},
			]"
		/>
	</AppModal>
</template>

<script>
import AppTabSet from './AppTabSet';
import TheAboutTab from './TheAboutTab';
import TheRewardsTab from './TheRewardsTab';
import TheSettingsTab from './TheSettingsTab';

export default {
	components: {
		AppTabSet,
	},
	props: {
		about: {
			type: String,
			default: null,
		},
		privacyPolicy: {
			type: String,
			default: null,
		},
		termsOfUse: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			TheAboutTab,
			TheRewardsTab,
			TheSettingsTab,
		};
	},
};
</script>
