<template>
	<AppScreen
		:name="id"
		class="demosurvey demosurvey--question"
		:transition="{
			name: 'build',
			duration: 2000,
		}"
	>
		<div class="demosurvey__body">
			<h3 class="demosurvey__title">
				{{ title }}
			</h3>
			<p
				v-if="multiple_choice"
				class="demosurvey__subtitle"
			>
				{{ $l10n( 'multiple-choice' ) }}
			</p>
			<OptionButtons
				v-model="selected"
				:options="options"
				:none-option="none_option"
				:multiple="multiple_choice"
				is-small
			/>
		</div>
		<div class="demosurvey__footer">
			<AppButton
				is-link
				@click="logAnswer('abstain')"
			>
				{{ $l10n( 'skip' ) }}
			</AppButton>

			<AppButton
				is-primary
				:disabled="selected.length === 0"
				@click="logAnswer()"
			>
				{{ $l10n( 'submit' ) }}
			</AppButton>
		</div>
	</AppScreen>
</template>

<script>
import OptionButtons from './OptionButtons.vue';

export default {
	components: {
		OptionButtons,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
		name: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		answers: {
			type: Array,
			required: true,
		},
		none_option: Boolean,
		multiple_choice: Boolean,
		index: {
			type: Number,
			required: true,
		},
		total: {
			type: Number,
			required: true,
		},
	},
	data() {
		return {
			options: this.answers.map( ( answer ) => {
				return {
					value: answer.name,
					label: answer.text,
				};
			} ),
			selected: [],
		};
	},
	methods: {
		logAnswer( answer ) {
			answer = answer || this.selected;

			if ( ! ( answer instanceof Array ) ) {
				answer = [ answer ];
			}

			this.$log.demographic( {
				question_name: this.name,
				answers: answer,
			} );

			this.$emit( 'next' );
		},
	},
};
</script>
