
<template>
	<div
		:class="{
			'option-group': true,
			'is-stacked': isStacked,
			'is-grid': isStacked && options.length > 3,
		}"
	>
		<div class="option-group__inner">
			<button
				v-for="(option, index) in options"
				:key="option.value"
				:class="{
					'option': true,
					'is-large': isLarge,
					'is-small': isSmall,
					'is-active': hasSelected( option.value ),
				}"
				:style="{ '--index': index + 1 }"
				@click="toggleOption( option.value )"
			>
				<div class="option__inner">
					<AppImage
						v-if="withGraphics && option.emote"
						:src="require(`@/assets/img/emotes/${ avatar.name }-${ option.emote }.jpg`)"
					/>
					<span class="option__text">
						{{ option.label || option.value }}
					</span>
				</div>
			</button>
		</div>
		<button
			v-if="noneOption"
			:class="{
				'option': true,
				'is-none': true,
				'is-large': isLarge,
				'is-small': isSmall,
				'is-active': hasSelected( noneOptionValue ),
			}"
			:style="{ '--index': options.length }"
			@click="overrideOption( noneOptionValue )"
		>
			<div class="option__inner">
				<span class="option__text">
					{{ $l10n( 'not-represented' ) }}
				</span>
			</div>
		</button>
	</div>
</template>

<script>
import { mapState } from 'vuex';

const NONE_OPTION_VALUE = 'Not Represented';

export default {
	emits: [ 'change' ],
	model: {
		prop: 'selected',
		event: 'change',
	},
	props: {
		options: {
			type: Array,
			required: true,
		},
		selected: {
			type: [ String, Array ],
			default: () => [],
		},
		multiple: Boolean,
		noneOption: Boolean,

		// Style options
		isStacked: Boolean,
		isLarge: Boolean,
		isSmall: Boolean,
		withGraphics: Boolean,
	},
	data() {
		return {
			noneOptionValue: NONE_OPTION_VALUE,
		};
	},
	computed: mapState( [ 'avatar' ] ),
	methods: {
		hasSelected( option ) {
			if ( this.multiple ) {
				return this.selected.includes( option );
			}

			return this.selected === option;
		},
		toggleOption( option ) {
			this.$sounds.play( 'click' );

			let changes = option;
			if ( this.multiple ) {
				changes = this.selected.filter( val => val !== NONE_OPTION_VALUE );
				if ( changes.includes( option ) ) {
					changes = this.selected.filter( val => val !== option );
				} else {
					changes.push( option );
				}
			}

			this.$emit( 'change', changes );
		},
		overrideOption( value ) {
			this.$sounds.play( 'click' );

			this.$emit( 'change', [ value ] );
		},
	},
};
</script>

<style lang="scss">
.option-group {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: rem(14);

	&__inner {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;

		.is-stacked & {
			flex-direction: column;
		}

		.is-grid & {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: rem(10);
		}
	}
}

.option {
	border-radius: 10em;
	font-weight: $font-weight-medium;
	line-height: 1;
	text-align: left;
	color: $color-black;
	background: rgba(#000, .2);
	box-shadow: 0 0 0 0 rgba(#000, .2);
	margin: 0 .25em .5em;
	transition: box-shadow .2s;

	&:last-child {
		margin-right: 0;
	}

	.is-stacked & {
		margin: 0 0 .5em;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.is-grid & {
		margin: 0;
	}

	&__inner {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		background: $color-white;
		padding: rem(20);
		border-radius: inherit;
		overflow: hidden;
		transition: transform .2s, background .2s;
	}

	&__text:only-child {
		margin: auto;
	}

	&:hover {
		box-shadow: 0 0 0 3px;
	}

	&:active,
	&.is-active {
		box-shadow: 0 0 0 2px;

		.option__inner {
			background: $color-light;
		}
	}

	&:focus-visible {
		box-shadow: 0 0 0 2px;

		.option__inner {
			background: $color-light;
		}
	}

	&.is-small {
		font-size: rem(14);

		.option__inner {
			padding: rem(10) rem(14);
		}

		@include breakpoint($min-height: 700, $min-width: 700) {
			font-size: rem(16);

			.option__inner {
				padding: rem(14) rem(18);
			}
		}
	}

	&.is-large {
		width: 100%;
		max-width: rem(340);
		margin: 0 .4em .8em;
		box-shadow: 0 6px 0 rgba(#000, .2);

		.is-stacked & {
			margin: 0 0 .8em;
		}
		.is-grid & {
			margin: 0;
		}

		.option__inner {
			padding: 0 rem(24);
			min-height: rem(60);
		}

		&:hover {
			.option__inner {
				transform: translateY(-6px);
			}
		}

		&:active,
		&.is-active {
			box-shadow: 0 2px 0 rgba(#000, .2);

			.option__inner {
				transform: none;
				background: $color-white;
			}
		}

		&:focus-visible {
			box-shadow: 0 2px 0 rgba(#000, .2);

			.option__inner {
				transform: none;
				background: $color-white;
			}
		}

		&:focus-visible {
			animation: wiggle .5s -.25s ease-in-out alternate infinite;
			outline: none;
		}

		.image {
			flex: none;
			width: rem(60);
			height: auto;
			margin-right: rem(10);
			margin-left: rem(-10);
			object-fit: contain;
		}

		.build-enter-active & {
			animation: slideInBottom .4s ease-out both;
			animation-delay: calc(.4s + (var(--index) * .2s));
		}
		.build-leave-active & {
			animation: slideOutBottom .4s ease-out both;
		}

		@include breakpoint($min-height: 700, $min-width: 700) {
			.option__inner {
				min-height: rem(80);
				padding: 0 rem(50);
			}

			.image {
				width: rem(80);
				margin-right: rem(30);
				margin-left: rem(-20);
			}
		}
	}

	&.is-none {
		color: $color-white;

		.option__inner {
			background: $color-black;
		}

		&:active,
		&.is-active {
			.option__inner {
				background: $color-dark;
			}
		}

		&:focus-visible {
			.option__inner {
				background: $color-dark;
			}
		}
	}
}
</style>
