<template>
	<Component
		:is="tag"
		:type="tag === 'button' ? type : null"
		:class="{
			'button': ! isPlain,

			'is-primary': isPrimary,
			'is-secondary': isSecondary,
			'is-link': isLink,

			'is-large': isLarge,
			'is-small': isSmall,
			[ `is-color-${ color }` ]: color,

			'with-icon': !! icon,
			'with-text': withText,
			'with-graphic': graphic,
		}"
		:tabindex="isParentActive ? null : -1"
		v-bind="attrs"
		@click="onClick"
	>
		<span
			v-if="icon"
			class="button__inner"
		>
			<span
				v-if="withText"
				class="button__text"
			>
				<slot />
			</span>
			<ScreenReaderText v-else>
				<slot />
			</ScreenReaderText>

			<AppGraphic :type="icon" />
		</span>
		<span
			v-else
			class="button__inner"
		>
			<AppImage
				v-if="graphic"
				:srcset="graphic"
				class="button__graphic"
				width="90"
				height="90"
			/>
			<span class="button__text">
				<slot />
			</span>
		</span>
	</Component>
</template>

<script>
import { mapState } from 'vuex';

export default {
	inject: [ 'parentView' ],
	props: {
		name: {
			type: String,
			default: '',
		},
		location: {
			type: String,
			default: '',
		},
		question: {
			type: String,
			default: '',
		},
		icon: {
			type: String,
			default: '',
		},
		graphic: {
			type: Array,
			default: null,
		},
		color: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: 'button',
		},
		href: {
			type: String,
			default: '',
		},
		newTab: Boolean,
		download: Boolean,

		// Roll styles
		isPrimary: Boolean,
		isSecondary: Boolean,
		isLink: Boolean,

		// Shape styles
		isSmall: Boolean,
		isLarge: Boolean,

		// Misc Styles
		isPlain: Boolean,
		withText: Boolean,
	},
	computed: {
		...mapState( [
			'currentScreen',
			'activeModals',
		] ),

		tag() {
			return this.href ? 'a' : 'button';
		},
		attrs() {
			return this.href ? {
				href: this.href,
				target: this.newTab ? '_blank' : undefined,
				download: this.download ? '' : undefined,
			} : {};
		},
		isParentActive() {
			// Modals open, only the current modal gets focusable buttons
			if ( this.activeModals.length > 0 ) {
				return this.activeModals.includes( this.parentView );
			}

			// No parent view (root), allow
			if ( ! this.parentView ) {
				return true;
			}

			// Must belong to the current screen
			return this.parentView === this.currentScreen;
		},
	},
	methods: {
		onClick( event ) {
			this.$sounds.play( 'click' );

			if ( this.name ) {
				this.$log.event( {
					event_type: 'click',
					target: this.name,
					location: this.location,
					question_name: this.question,
				} );
			}

			this.$emit( 'click', event );
		},
	},
};
</script>

<style lang="scss">
@keyframes wiggle {
	from {
		transform: rotate(-4deg);
	}
	to {
		transform: rotate(4deg);
	}
}

button {
	background: transparent;
	cursor: pointer;
}

.button {
	position: relative;
	display: block;
	font-weight: $font-weight-medium;
	text-decoration: none;
	border-radius: rem(36);
	background: rgba(#000, .2);
	box-shadow: 0 6px 0 rgba(#000, .2);
	transition: box-shadow .2s ease-out;

	&__inner {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		border-radius: inherit;

		.button & {
			background: $color-dark;
			color: $color-white;
			padding: rem(14) rem(20) rem(10);
			overflow: hidden;
			transition: transform .2s ease-out;
		}
	}

	&:hover {
		.button__inner {
			transform: translateY(-9px);
		}
	}

	&:active,
	&.is-active {
		box-shadow: 0 2px 0 rgba(#000, .2);

		.button__inner {
			transform: none;
		}
	}

	&:disabled {
		box-shadow: 0 6px 0 rgba(#000, .2);

		.button__inner {
			opacity: .7;
			transform: none;
		}
	}

	&:focus-visible {
		outline: none;
		animation: wiggle .5s -.25s ease-in-out alternate infinite;
	}

	&.is-primary,
	&.is-secondary {
		.button__inner {
			background: $color-white;
		}
	}

	&.is-primary {
		.button__inner {
			font-weight: $font-weight-black;
			color: $color-pink;
		}
	}

	&.is-secondary {
		.button__inner {
			color: $color-black;
		}
	}

	&.is-color-green {
		.button__inner {
			background: $color-green;
			color: $color-black;
		}
	}

	&.is-color-pink {
		.button__inner {
			background: $color-pink;
			color: $color-white;
		}
	}

	&.is-small {
		font-size: rem(14);
		box-shadow: 0 0 0 0 $color-white;

		.button__inner {
			padding: rem(7) rem(17);
		}

		&:hover {
			box-shadow: 0 0 0 3px $color-white;

			.button__inner {
				transform: none;
			}
		}

		&:active {
			box-shadow: 0 0 0 1px $color-white;

			.button__inner {
				background: $color-black;
			}
		}

		&:focus-visible {
			box-shadow: 0 0 0 1px $color-white;

			.button__inner {
				background: $color-black;
			}
		}
	}

	&.is-large {
		.button__inner {
			padding: rem(20);
			min-height: rem(64);
		}
	}

	.icon {
		flex: none;
	}

	&.with-text .icon {
		@include size(1em);
		fill: currentColor;
		margin-left: .5em;
		margin-top: -.2em;
	}

	&.with-graphic {
		font-weight: $font-weight-medium;
	}
	.with-graphic & {
		&__inner {
			background: $color-white;
			color: $color-black;
			min-height: rem(90);
			max-width: rem(350);
			padding: 0 rem(50);
			text-align: left;
		}
			&__graphic {
				flex: none;
				width: rem(90);
				height: auto;
				margin-top: auto;
				margin-right: rem(30);
				margin-left: rem(-20);
				object-fit: contain;
			}
	}

	@include breakpoint($max-height: 600px) {
		.with-graphic & {
			&__inner {
				min-height: rem(70);
			}
				&__graphic {
					width: rem(70);
				}
		}
	}

	@include breakpoint($max-height: 500px) {
		.with-graphic & {
			&__inner {
				min-height: rem(60);
			}
				&__graphic {
					width: rem(60);
				}
		}
	}
}
</style>
