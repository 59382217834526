<template>
	<Transition>
		<div
			v-if="isOpen"
			:class="{
				'modal': true,
				'is-open': isOpen,
				'is-mini': isMini,
				[ className ]: true
			}"
			:style="{ zIndex }"
			tabindex="-1"
			@keyup.esc="close"
			@click="maybeClose"
		>
			<div class="modal__frame">
				<div
					v-if="content"
					class="modal__body"
					v-html="content"
				/>
				<div
					v-else
					class="modal__body"
				>
					<slot />
				</div>

				<div
					v-if="! noClose"
					class="modal__actions"
				>
					<AppButton
						ref="close"
						name="close"
						:location="name"
						is-small
						class="modal__close"
						@click="close"
					>
						{{ $l10n( 'close' ) }}
					</AppButton>
					<slot name="actions" />
				</div>
			</div>
		</div>
	</Transition>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

const MODAL_ZINDEX = 10;

export default {
	provide() {
		return {
			parentView: this.name,
		};
	},
	inheritAttrs: false,
	props: {
		name: {
			type: String,
			required: true,
		},
		content: {
			type: String,
			default: '',
		},
		className: {
			type: String,
			default: '',
		},
		isMini: Boolean,
		isBubble: Boolean,
		autoShow: Boolean,
		noClose: Boolean,
	},
	computed: mapState( {
		isOpen( state ) {
			return state.activeModals.includes( this.name );
		},
		zIndex( state ) {
			return MODAL_ZINDEX + state.activeModals.indexOf( this.name );
		},
	} ),
	watch: {
		isOpen( isOpen ) {
			if ( isOpen ) {
				this.$emit( 'show' );
				this.$nextTick( () => this.$el.focus() );
			} else {
				this.$el.blur();
				this.$emit( 'hide' );
			}

			this.$emit( 'toggle', isOpen );
		},
	},
	mounted() {
		if ( this.autoShow ) {
			this.showModal( this.name );
		}
	},
	methods: mapMutations( {
		close( commit ) {
			this.$emit( 'close' );
			commit( 'hideModal', this.name );
		},
		maybeClose( commit, e ) {
			if ( e.target === this.$el && ! this.noClose ) {
				this.close( commit );
			}
		},
	} ),
};
</script>

<style lang="scss">
.modal {
	position: fixed;
	inset: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	overflow: auto;
	backdrop-filter: blur(4px);

	&.is-mini {
		padding: rem(20);
	}

	&__frame {
		flex: 1;
		display: flex;
		flex-direction: column;
		width: 100%;
		background: $color-white;
		color: $color-black;
		margin: auto;
		border-radius: rem(10) rem(10) 0 0;

		.is-mini & {
			flex: none;
			max-width: rem(380);
			border-radius: rem(24);
			box-shadow: 0 6px 0 rgba(#000, .2);
			text-align: center;
		}
	}
		&__actions {
			order: -1;
			display: flex;
			flex-direction: row-reverse;
			padding: rem(16) rem(40) 0;
		}
		&__body {
			padding: rem(30) rem(30) rem(30);

			.is-mini & {
				padding: rem(48) rem(30) rem(30);
			}

			> :first-child {
				margin-top: 0;
			}
			> :last-child {
				margin-bottom: 0;
			}
		}

	@include breakpoint($min-width: 600) {
		padding: rem(40);

		&__frame {
			flex: none;
			border-radius: rem(24);
			max-width: rem(500);
		}
			&__actions {
				padding-top: rem(30);
			}
	}

	&.v-enter-active,
	&.v-leave-active {
		overflow: hidden;
		transition: opacity .2s, visibility .4s, z-index .4s;

		.modal__frame {
			transition: transform .2s ease-out;
		}
	}

	&.v-enter-active {
		.modal__frame {
			transition-delay: .2s;
		}

		&.immediate {
			&, .modal__frame {
				transition: none;
			}
		}
	}

	&.v-leave-active {
		transition-delay: .2s, 0s;
	}

	&.v-enter,
	&.v-leave-to {
		opacity: 0;
		visibility: hidden;

		.modal__frame {
			transform: translateY(100vh);
		}
	}

	.build-leave-active & {
		.modal__frame {
			animation: slideOutBottom .4s ease-out both;
		}
	}
}
</style>
