<template>
	<AppScreen
		name="disclaimer"
		class="legalscreen"
	>
		<div
			class="legalscreen__header"
			v-html="content"
		/>
		<div class="legalscreen__body">
			<div class="button-group is-stacked">
				<AppButton
					name="start"
					location="disclaimer"
					:graphic="[
						require(`@/assets/img/emotes/${ avatar.name }-happy.jpg`)
					]"
					@click="$emit( 'accept' )"
				>
					{{ $l10n( 'disclaimer-accept' ) }}
				</AppButton>
			</div>
		</div>
	</AppScreen>
</template>

<script>
import { mapState } from 'vuex';

export default {
	emits: [ 'accept', 'reject' ],
	props: {
		content: {
			type: String,
			required: true,
		},
	},
	computed: mapState( [ 'avatar' ] ),
};
</script>

<style lang="scss">
.legalscreen {
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: rem(64) rem(30);
}
</style>
