<template>
	<div>
		<TheDemographicLegalScreen
			@submit="startSurvey"
		>
			<div v-html="why_content" />
		</TheDemographicLegalScreen>

		<DemographicQuestionScreen
			v-for="( question, index ) in questions"
			:key="question.id"
			v-bind="question"
			:index="index"
			:total="questions.length"
			@next="nextQuestion"
		/>

		<AppScreen
			name="demosurvey-end"
			class="demosurvey demosurvey--end"
		>
			<div class="demosurvey__header">
				<h2>{{ $l10n( 'demosurvey-thanks' ) }}</h2>
				<p>{{ $l10n( 'demosurvey-thanks-detail' ) }}</p>
			</div>
			<div
				class="demosurvey__graphic"
				aria-hidden="true"
			>
				<AppCharacter
					:type="avatar.name"
					:color="avatar.color"
					animation="reaction"
					clip="love"
				/>
			</div>
			<div class="demosurvey__footer">
				<AppButton
					name="finish"
					location="demosurvey"
					is-primary
					@click="$emit( 'done' )"
				>
					{{ $l10n( 'continue' ) }}
				</AppButton>
			</div>
		</AppScreen>

		<AppModal
			name="survey-invite"
			class="survey-invite"
			is-mini
			no-close
		>
			<AppCharacter
				type="capacitor"
				color="yellow"
				animation="interaction"
				clip="picked"
			/>

			<h2>{{ $l10n( 'demosurvey-invite' ) }}</h2>
			<p>{{ $l10n( 'demosurvey-invite-detail' ) }}</p>
			<div class="button-group is-spaced">
				<AppButton
					name="skip-survey"
					location="demosurvey"
					@click="hideModal( 'survey-invite' ), $emit( 'skip' )"
				>
					{{ $l10n( 'survey-no' ) }}
				</AppButton>
				<AppButton
					name="start-survey"
					location="demosurvey"
					color="green"
					@click="hideModal( 'survey-invite' ), goToScreen( 'demosurvey' )"
				>
					{{ $l10n( 'survey-yes' ) }}
				</AppButton>
			</div>
		</AppModal>
	</div>
</template>

<script>
/* eslint-disable vue/prop-name-casing */

import { mapState, mapMutations } from 'vuex';

import TheDemographicLegalScreen from './TheDemographicLegalScreen.vue';
import DemographicQuestionScreen from './DemographicQuestionScreen';

export default {
	components: {
		TheDemographicLegalScreen,
		DemographicQuestionScreen,
	},
	inheritAttrs: false,
	props: {
		why_content: {
			type: String,
			required: true,
		},
		questions: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			currentQuestion: null,
		};
	},
	computed: {
		...mapState( [ 'avatar' ] ),
	},
	methods: {
		...mapMutations( [ 'goToScreen' ] ),

		goToQuestion( index ) {
			if ( typeof index === 'number' && index < this.questions.length ) {
				this.currentQuestion = index;
				this.goToScreen( this.questions[ index ].id );
			} else {
				this.currentQuestion = null;
				this.goToScreen( 'demosurvey-end' );
			}
		},
		nextQuestion() {
			this.goToQuestion( this.currentQuestion + 1 );
		},

		startSurvey() {
			this.$log.demographic( {
				question_name: 'consent',
				answers: [ 'yes' ],
			} );
			this.goToQuestion( 0 );
		},
		skipSurvey() {
			this.$log.demographic( {
				question_name: 'consent',
				answers: [ 'no' ],
			} );
			this.goToScreen( 'demosurvey-end' );
		},
	},
};
</script>

<style lang="scss">
.demosurvey {
	justify-content: flex-start;
	overflow: auto;

	&__header,
	&__body {
		.build-enter-active & {
			animation: slideInLeft .4s ease-out both;
		}
		.build-leave-active & {
			animation: slideOutRight .4s ease-out both;
		}
	}
	&__header {
		margin: auto;
		text-align: center;
	}
	&__body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		margin: auto;

		.demosurvey--intro & {
			justify-content: flex-start;
			flex: 0 1 100%;
			text-align: left;
			overflow: auto;
			padding-bottom: rem(80);
		}
	}
		&__title {
			text-align: center;
			margin-bottom: rem(10);
		}
		&__subtitle {
			margin: rem(-10);
			margin-bottom: 0;
		}

	&__graphic {
		flex: 1;
		max-height: 300px;
		position: relative;

		.character {
			position: absolute;
			top: 40px;
			left: 50%;
			width: rem(270);
			transform: translateX(-50%);
		}

		+ .demosurvey__footer {
			margin-top: 0;
		}
	}

	&__footer {
		position: absolute;
		z-index: 3;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		display: flex;
		justify-content: flex-end;
		padding: 0 rem(20) rem(20);

		> :not(:first-child) {
			margin-left: 1em;
		}

		.build-enter-active & {
			animation: slideInBottom .4s ease-out both;
			animation-delay: .4s;
		}
		.build-leave-active & {
			animation: slideOutBottom .4s ease-out both;
		}
	}
}

.demosurvey--question {
	padding-bottom: rem(70);
}

.demosurvey--end {
	overflow: hidden;
}

.survey-invite {
	.modal__body {
		position: relative;
		padding-top: rem(200);
		overflow: hidden;
	}

	.character {
		position: absolute;
		top: 0;
		left: 50%;
		width: 180px;
		transform: translateX(-75%) translateY(-35%) rotate(150deg);
	}
}
</style>
