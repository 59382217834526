<template>
	<div :class="[ 'slider-question', `has-layout-${ layout }` ]">
		<div class="slider-question__header">
			<h2
				v-if="title"
				v-html="title"
			/>
			<div v-html="content" />
		</div>

		<div class="slider-question__body">
			<div class="slider">
				<div
					ref="bar"
					class="slider__bar"
				>
					<div class="slider__markers">
						<input
							v-for="i in stops"
							:key="i"
							type="radio"
							class="slider__marker"
							:name="`v${ _uid }`"
							@focus="setValue( i - steps - 1 )"
							@change="setValue( i - steps - 1 )"
						/>
					</div>
					<div
						ref="thumb"
						class="slider__thumb"
						:data-position="step"
					>
						<AppTooltip class="slider__tooltip">
							{{ label }}
						</AppTooltip>
					</div>
				</div>

				<div class="slider__labels">
					<span class="slider__label">{{ leftLabel }}</span>
					<span class="slider__label">{{ rightLabel }}</span>
				</div>
			</div>
		</div>

		<div
			v-if="! noGraphic"
			class="slider-question__graphic"
			aria-hidden="true"
		>
			<AppCharacter
				:type="avatar.name"
				animation="reaction"
				v-bind="character"
			>
				<AppTooltip
					v-if="isVertical"
					class="slider-question__tooltip"
				>
					{{ label }}
				</AppTooltip>
			</AppCharacter>
		</div>

		<div class="slider-question__footer">
			<AppButton
				v-if="isSkippable"
				is-circular
				@click="$emit( 'answer', 'abstain' )"
			>
				{{ $l10n( 'skip' ) }}
			</AppButton>
			<AppButton
				is-primary
				is-circular
				@click="$emit( 'answer', value )"
			>
				{{ $l10n( 'submit' ) }}
			</AppButton>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import { gsap, Draggable } from '@/utilities/gsap';

const CHARACTERS = [
	{
		color: 'purple',
		clip: 'surprise',
	},
	{
		color: 'green',
		clip: 'woah',
	},
	{
		color: 'teal',
		clip: 'hmm',
	},
	{
		color: 'blue',
		clip: 'ohh',
	},
	{
		color: 'yellow',
		clip: 'happy',
	},
];

export default {
	inheritAttrs: false,
	props: {
		title: {
			type: String,
			required: true,
		},
		content: {
			type: String,
			required: true,
		},
		labels: {
			type: Array,
			required: true,
		},
		values: {
			type: Array,
			required: true,
		},
		layout: {
			type: String,
			default: 'vertical',
		},
		noGraphic: Boolean,
		isSkippable: Boolean,
	},
	data() {
		const isVertical = this.layout === 'vertical';

		// Auto determine step size based on value list length (e.g. 5 = 2 steps, +2 to -2)
		const steps = Math.floor( this.values.length / 2 );

		// Create a map of the values/labels to the numberic values (e.g. +2 to -2)
		const valueMap = new Map( this.values.map( ( value, index ) => [ steps - index, value ] ) );
		const labelMap = new Map( this.labels.map( ( label, index ) => [ steps - index, label ] ) );

		// Get the lowest/hightest level labels for horizontal display
		const leftLabel = labelMap.get( steps * -1 );
		const rightLabel = labelMap.get( steps );

		// Get the middle/initial/default value/label
		const initValue = valueMap.get( 0 );
		const initLabel = labelMap.get( 0 );

		return {
			isVertical,
			dragAxis: isVertical ? 'y' : 'x',
			dragProp: isVertical ? 'endY' : 'endX',
			boxDimension: isVertical ? 'height' : 'width',
			valueMap,
			labelMap,
			step: 0,
			value: initValue,
			label: initLabel,
			steps,
			leftLabel,
			rightLabel,
		};
	},
	computed: {
		...mapState( [ 'avatar' ] ),

		stops() {
			return ( this.steps * 2 ) + 1;
		},

		character() {
			return CHARACTERS[ this.step + this.steps ];
		},
	},
	mounted() {
		const slider = this;
		const { bar, thumb } = this.$refs;

		const dragAxis = this.layout === 'vertical' ? 'y' : 'x';
		const dragProp = this.layout === 'vertical' ? 'endY' : 'endX';

		const [ drag ] = Draggable.create( thumb, {
			zIndexBoost: false,
			bounds: bar,
			type: dragAxis,
			onDrag() {
				slider.calculateSnap( this[ dragProp ] );
			},
			onDragEnd() {
				const snap = slider.calculateSnap( this[ dragProp ] ) * ( slider.isVertical ? -1 : 1 );

				this.disable();
				gsap.to( thumb, {
					[ dragAxis ]: snap,
					ease: 'none',
					duration: .2,
					onComplete: () => {
						this.enable().update();
					},
				} );
			},
		} );

		this.draggable = drag;
	},
	methods: {
		calculateSnap( position ) {
			const { bar, thumb } = this.$refs;

			const barBounds = bar.getBoundingClientRect();
			const thumbBounds = thumb.getBoundingClientRect();

			const range = ( barBounds[ this.boxDimension ] - thumbBounds[ this.boxDimension ] ) / 2;
			const stopSize = range / this.steps;

			this.step = Math.round( position / stopSize ) * ( this.isVertical ? -1 : 1 );
			this.value = this.valueMap.get( this.step );
			this.label = this.labelMap.get( this.step );

			return this.step * stopSize;
		},
		setValue( value ) {
			const { bar, thumb } = this.$refs;

			const barBounds = bar.getBoundingClientRect();
			const thumbBounds = thumb.getBoundingClientRect();

			const range = ( barBounds[ this.boxDimension ] - thumbBounds[ this.boxDimension ] ) / 2;
			const stopSize = range / this.steps;

			this.step = value;
			this.value = this.valueMap.get( this.step );
			this.label = this.labelMap.get( this.step );

			this.draggable.disable();
			gsap.to( thumb, {
				[ this.dragAxis ]: value * stopSize,
				ease: 'none',
				duration: .2,
				onComplete: () => {
					this.draggable.enable().update();
				},
			} );
		},
	},
};
</script>

<style lang="scss">
.slider-question {
	position: relative;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	text-align: center;
	width: 100%;
	max-width: rem(600);
	margin-inline: auto;

	&.has-layout-vertical {
		padding-left: 130px;
		text-align: left;
	}

	&__header {
		flex: none;
		margin-block: auto;

		h2 {
			font-size: 1em;
		}

		.build-enter-active & {
			animation: slideInTop .4s ease-out both;
		}
		.build-leave-active & {
			animation: slideOutTop .4s ease-out both;
		}

		.has-layout-horizontal & {
			.build-enter-active & {
				animation: slideInLeft .4s ease-out both;
			}
			.build-leave-active & {
				animation: slideOutLeft .4s ease-out both;
			}
		}
	}

	&__body {
		.has-layout-vertical & {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100px;
		}

		.build-enter-active & {
			animation: slideInLeft .4s ease-out both;
			animation-delay: .4s;
		}
		.build-leave-active & {
			animation: slideOutRight .4s ease-out both;
		}
	}

	&__graphic {
		position: relative;
		flex: 1 1 0;
		margin-top: auto;
		width: 100%;

		.character {
			position: absolute;
			top: 0;
			width: 200px;
		}

		.has-layout-horizontal & {
			max-height: 200px;
			margin-bottom: rem(-30);

			.character {
				left: -20px;
			}
		}
		.has-layout-vertical & {
			max-height: 230px;
			margin-bottom: rem(-30);

			.character {
				right: -20px;
			}
		}

		.build-enter-active & {
			animation: slideInBottom .4s ease-out both;
			animation-delay: .6s;
		}
		.build-leave-active & {
			animation: slideOutBottom .4s ease-out both;
		}
	}

	&__footer {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-top: auto;

		> :not(:first-child) {
			margin-left: 1em;
		}

		.build-enter-active & {
			animation: slideInBottom .4s ease-out both;
			animation-delay: .8s;
		}
		.build-leave-active & {
			animation: slideOutBottom .4s ease-out both;
		}
	}

	@include breakpoint($min-height: 800) {
		.has-layout-horizontal & {
			&__header {
				margin-top: auto;
			}
			&__body {
				margin-bottom: auto;
			}
		}
	}
}

.slider {
	width: 100%;

	&__bar {
		position: relative;
		background: rgba(#fff, .5);
		border-radius: 100px;

		.has-layout-horizontal & {
			height: rem(64);
			margin-top: rem(80);
			margin-bottom: rem(16);
		}
	}
		&__markers {
			position: absolute;
			display: flex;
			align-items: center;
			width: 100%;
			height: 100%;

			.has-layout-horizontal & {
				flex-direction: row;
				top: 50%;
				left: 0;
				width: 100%;
				transform: translateY(-50%);
			}

			.has-layout-vertical & {
				top: 0;
				left: 50%;
				height: 100%;
				flex-direction: column;
				transform: translateX(-50%);
			}
		}
			&__marker {
				position: relative;
				display: block;
				margin: auto;

				&::before {
					content: '';
					position: absolute;
					inset: 0;
					@include size(20);
					background: #fff;
					border-radius: 50%;
					margin: auto;
				}

				&:focus {
					box-shadow: 0 0 0 4px $color-black;
				}

				.has-layout-horizontal & {
					@include size(64);

					&:first-child {
						margin-left: 0;
					}
					&:last-child {
						margin-right: 0;
					}
				}
				.has-layout-vertical & {
					width: 100%;
					@include aspect;

					&:first-child {
						margin-top: 0;
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		&__thumb {
			position: absolute;
			top: 50%;
			left: 50%;
			background: #48C9D4 center no-repeat;
			background-size: cover;
			border-radius: 50%;
			transition: background .2s;

			&[data-position="2"] {
				background: #E3BC18;
			}
			&[data-position="1"] {
				background: #0194FF;
			}
			&[data-position="-1"] {
				background: #0ED075;
			}
			&[data-position="-2"] {
				background: #9C45D2;
			}

			.has-layout-horizontal & {
				@include size(64px);
				margin-top: -32px;
				margin-left: -32px;
				background-image: url(../assets/img/drag-horizontal.svg);
			}
			.has-layout-vertical & {
				@include size(100px);
				margin-top: -50px;
				margin-left: -50px;
				background-image: url(../assets/img/drag-vertical.svg);
			}
		}

	&__labels {
		display: flex;
		justify-content: space-between;
		font-size: rem(16);
		font-weight: $font-weight-heavy;
	}

	.has-layout-vertical & {
		&, &__bar {
			height: 100%;
		}
		&__tooltip,
		&__labels {
			display: none;
		}
	}
}

.slider-question__tooltip {
	margin-bottom: 0;
}

.slider__tooltip {
	margin-bottom: 26px;
}
</style>
