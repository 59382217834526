<template>
	<AppScreen
		name="employeeinfo"
		class="formscreen"
		:transition="{ name: 'build' }"
	>
		<form
			ref="form"
			class="formscreen__body"
			:style="{ '--required': `'${ $l10n( 'required' ) }'` }"
			@submit.prevent="logAnswers"
		>
			<h2>{{ $l10n( 'ask-employee-info' ) }}</h2>
			<div
				v-if="askSiteId"
				class="form-field is-required"
			>
				<label
					for="employeeinfo_siteid"
					class="form-field__label"
				>
					{{ $l10n( 'site-id' ) }}
				</label>
				<input
					id="employeeinfo_siteid"
					v-model="siteId"
					class="form-field__input"
					type="text"
					placeholder="ID-###-####-###"
					required
				/>
			</div>
			<div
				v-if="askExperience"
				class="form-field is-required"
			>
				<label
					for="employeeinfo_experience"
					class="form-field__label"
				>
					{{ $l10n( 'years-experience' ) }}
				</label>
				<select
					id="employeeinfo_experience"
					v-model="experience"
					class="form-field__input"
					type="text"
					required
				>
					<option
						:value="null"
						disabled
					>
						{{ $l10n( 'select-option' ) }}
					</option>
					<option value="< 1 month">
						{{ $l10n( 'experience-lt1month' ) }}
					</option>
					<option value="1 month - 1 year">
						{{ $l10n( 'experience-lte1year' ) }}
					</option>
					<option value="> 1 year">
						{{ $l10n( 'experience-gt1year' ) }}
					</option>
				</select>
			</div>

			<div class="formscreen__footer">
				<AppImage
					:src="require('@/assets/img/avatar-capacitor.jpg')"
					width="240"
					height="195"
				/>

				<AppButton
					name="submit"
					location="employeeinfo"
					color="green"
					type="submit"
					:disabled="! isValid"
				>
					{{ $l10n( 'next' ) }}
				</AppButton>
			</div>
		</form>
	</AppScreen>
</template>

<script>
export default {
	emits: [ 'submit' ],
	props: {
		askSiteId: Boolean,
		askExperience: Boolean,
	},
	data() {
		return {
			siteId: null,
			experience: null,
		};
	},
	computed: {
		isValid() {
			return ( ! this.askSiteId || this.siteId ) && ( ! this.askExperience || this.experience );
		},
	},
	methods: {
		logAnswers() {
			if ( this.askSiteId ) {
				this.$log.demographic( {
					question_name: 'employee-siteid',
					answers: [ this.siteId ],
				} );
			}
			if ( this.askExperience ) {
				this.$log.demographic( {
					question_name: 'employee-experience',
					answers: [ this.experience ],
				} );
			}

			this.$emit( 'submit' );
		},
	},
};
</script>

<style lang="scss">
.formscreen {
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: rem(64) rem(30);

	&__body {
		max-width: rem(380);
		padding: rem(40) rem(30);
		background: $color-white;
		border-radius: rem(24);
		box-shadow: 0 6px 0 rgba($color-black, .25);
		overflow: hidden;
	}

	&.build-enter-active &__body,
	&.build-leave-active &__body {
		transition: transform .4s ease-out, visibility .4s;
	}

	&.build-enter &__body,
	&.build-leave-to &__body {
		transform: translateY(100vh);
		visibility: hidden;
	}

	&__footer {
		display: flex;
		width: auto;
		justify-content: space-between;
		align-items: flex-end;
		margin: 0 rem(-30) rem(-40);

		button {
			margin: rem(20);
		}

		@include breakpoint($max-width: 600) {
			.image {
				width: 200px;
				height: auto;
			}
		}
	}
}

.form-field {
	font-size: rem(14);
	text-align: left;
	margin-block: rem(24);

	&__label,
	&__input {
		display: block;
	}

	&__label {
		margin-bottom: .5em;

		.is-required &::after {
			content: var(--required);
			float: right;
			font-size: rem(12);
			color: $color-pink;
		}
	}

	&__input {
		width: 100%;
		font-size: rem(16);
		line-height: 1;
		padding: 1em;
		background: $color-white;
		border: 1px solid rgba(#414040, .5);

		&::placeholder {
			color: rgba(#414040, .5);
		}
		&:focus {
			border-color: #414040;
		}
		&:invalid:focus {
			border-color: $color-pink;
			outline: 1px solid $color-pink;
		}
		&:focus-visible {
			outline: none;
		}
	}

	input {
		border-radius: 7px;
	}
	select {
		padding-right: 3em;
		border-radius: 2em;
		background: svg-shape-uri('<path fill="none" stroke="#1F1F1F" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" d="M31.2,20.2l-7.5,7.5l-7.5-7.5"/>', 48, 48) center right no-repeat;
		box-shadow: 0 6px 0 rgba(#000, .2);

		&:invalid {
			color: rgba(#414040, .5);
		}
	}
		option:not(:disabled) {
			color: $color-black;
		}
}
</style>
